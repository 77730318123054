// index.js (src/components/ChartNew/ChartOutput/NoDot)

//>>>>> PACKAGES <<<<<//
import React, { PureComponent } from "react";

//>>>>> COMPONENT - OpenDot <<<<<//
class NoDot extends PureComponent {
    
    render() {

        //>>>>> Return <<<<<//
        return (
            <svg
                x={this.props.cx}
                y={this.props.cy}
                width={6}
                height={6}
                viewBox="0 0 512 512"
            >
                <path />
            </svg>
        );  
    }
}

export default NoDot;


