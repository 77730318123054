// File: index.js (src/components/SignUp)

//>>>>> PACKAGES <<<<<//
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import navigate from "hookrouter";

//>>>>> FORMIK <<<<<//
import MyInput from "../../Formik/MyInput";

//>>>>> SASS STYLES <<<<<//
import "./styles.scss";

//>>>>> COMPONENT <<<<<//
const SignUp = ({ firebase }) => {

    const pwRegex1 = /(?=.*[0-9])/;
    const pwRegex2 = /(?=.*[!@#$%^&*])/;

    const [newuser, setNewUser] = useState(false);
    useEffect(() => {
        const createUser = async () => {  

            if (!newuser) { return };

            const { username, email, passwordOne, roles } = newuser;                    

            firebase
                .doCreateUserWithEmailAndPassword(email, passwordOne)
                .then(response => {
                    return firebase
                        .user(response.user.uid) // use token to open database 
                        .set({
                            username,
                            email,
                            roles,
                        });
                    // .user is my created method from firebase.js {uid => this.db.ref(`users/${uid}`)}
                    // .set is firebase api
                })
                .then(() => {
                    return firebase.doSendEmailVerification();
                })
                //.then(() => {
                //    navigate("/");
                //})
                // this doesn't work
                .catch(error => {
                    console.log("create user error catch", error);
                });

        };
        createUser();
    },[newuser]);

    return (
        <div
            id="SignUp-container"
        >
            <Formik
                initialValues={{
                    username: "",
                    email: "",
                    passwordOne: "",
                    passwordTwo: "",
                }}
                validationSchema={Yup.object({
                    username: Yup.string()
                        .required("Required")
                        .min(2, "Invalid Nickname")
                        .max(32, "Invalid Nickname"),
                    email: Yup.string()
                        .required("Required")
                        .min(2, "Invalid Email")
                        .max(64, "Invalid Email")
                        .email("Invalid Email"),
                    passwordOne: Yup.string()
                        .required("Required")
                        .matches(pwRegex1, "at least one number")
                        .matches(pwRegex2, "at least one special character")
                        .min(8, "min 8 characters")
                        .max(32, "max 32 characters"),
                    passwordTwo: Yup.string()
                        .oneOf([Yup.ref("passwordOne")], "password mismatch")
                        .required("Required")
                })}
                onSubmit={(values) => {
                    setNewUser({
                        ...values,
                        roles: { ADMIN: false }
                    });
                }}
            >                
                <Form className="Formik-Form" >
                    <div className="Formik-formRow" >      
                        <MyInput
                            title="Nickname"
                            className="Formik-MyInput"
                            label=""
                            name="username"
                            type="text"
                            placeholder="nickname"
                        >
                        </MyInput>  
                    </div>
                    <div className="Formik-formRow" >
                        <MyInput
                            title="Email Address"
                            className="Formik-MyInput"
                            label=""
                            name="email"
                            type="text"
                            placeholder="email address"
                        >
                        </MyInput>   
                    </div>   
                    <div className="Formik-formRow" >
                        <MyInput
                            title="Password"
                            className="Formik-MyInput"
                            label=""
                            name="passwordOne"
                            type="password"
                            placeholder="password"
                        >
                        </MyInput>
                    </div>  
                    <div className="Formik-formRow" >
                        <MyInput
                            title="Confirm Password"
                            className="Formik-MyInput"
                            label=""
                            name="passwordTwo"
                            type="password"
                            placeholder="confirm password"
                        >
                        </MyInput>
                    </div>  
                    <div className="Formik-formRow" >
                        <button
                            className=" Landing-bigButton"
                            type="submit"
                        >CREATE</button>
                    </div>  
                </Form>       
            </Formik>
        </div> 
    );
};

//>>>>> EXPORT <<<<<//
export default SignUp;

