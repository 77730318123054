// index.js (src/components/Landing/SignInGoogle)

//>>>>> PACKAGES <<<<<//
import React, { useState, useEffect } from "react";

//>>>>> IMAGES <<<<<//
import googleThumb from "../../../img/glogo32.png";

//>>>>> SASS STYLES <<<<<//
import "./styles.scss";

//>>>>> COMPONENT <<<<<//
const SignInGoogle = ({ firebase }) => {

    const [issubmitted, setIsSubmitted] = useState(false);
    useEffect(() => {
        const submitNewUser = () => {
            if (!issubmitted) {
                return;
            } else {
                firebase.doSignInWithGoogle()
                    .then(response => {
                        return firebase
                            .user(response.user.uid)
                            .set({
                                username: response.user.displayName,
                                email: response.user.email,
                                roles: {ADMIN: false},
                            });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
        submitNewUser();
    }, [issubmitted, firebase]);

    //>>>>> JSX Return <<<<<//
    return (
        <div
            id="Google-container"
            className="Base-block"
        >
            <button
                type="button"
                onClick={() => { setIsSubmitted(true) }}
            >
                <img
                    title="Sign In with Google"
                    src={googleThumb}
                    alt="Google"
                    disabled={issubmitted ? "true" : "false"}
                />
            </button>
        </div>
        );
};

//>>>>> EXPORT <<<<<//
export default SignInGoogle

