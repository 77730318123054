// context.js (src/components/Firebase)

//>>>>> IMPORT <<<<<//
import React from "react";

//>>>>> CONTEXT CREATION <<<<<//
const FirebaseContext = React.createContext(null);

//>>>>> CONTEXT CONSUMER <<<<<//
export const withFirebase = Component => props => (
    <FirebaseContext.Consumer>
        {firebase => <Component {...props} firebase={firebase} />}
    </FirebaseContext.Consumer>
);

export default FirebaseContext;

