// Title: index.js (src/components/ChartNew/RightPanel)

//>>>>>>>>>> PACKAGES <<<<<<<<<<//
import React from "react";

//>>>>>>>>>> COMPONENTS <<<<<<<<<<//
import ChartSettings from "./ChartSettings";
import GiphySearch from "./GiphySearch";
import GifFixed from "./GifFixed";

//>>>>>>>>>> SCSS STYLES <<<<<<<<<<//
import "./styles.scss";

//>>>>>>>>>> COMPONENT FUNCTION <<<<<<<<<<//
function RightPanel({
    onSettings,
    onDisplay,
    searchGifs,
    searchStickers,
    yData,
    sData,
    chartSettings
}) {   

    //>>>>>>>>>> JSX Return <<<<<<<<<<//
    return (
        <div id="RightPanel-container" >
            <div className="Base-flexedCol-block" >
                <ChartSettings 
                    chartSettings={chartSettings}
                    onDispatch={onSettings}
                />                
            </div>
            <div className="Base-flexedCol-block" ><h4>GIFS</h4></div>
            <div className="Base-flexedCol-block" >
                <GiphySearch                    
                    search={searchGifs}
                    api={"giphy-gifs"}
                />                
            </div>            
            <div className="Base-flexedCol-block" >
                {yData.status === 1 ?
                    (yData.data.map((item, index) =>
                    {
                        return (
                            <GifFixed
                                gifMeta={{
                                    index,
                                    url: item.embed_url,
                                    height: parseInt(item.images.original.height, 10),
                                    width: parseInt(item.images.original.width, 10),
                                    link: item.bitly_url,
                                }}
                                onDisplay={onDisplay}
                            />
                        );}
                    )) : (null)}
            </div>
            <div className="Base-flexedCol-block" ><h4>STICKERS</h4></div>
            <div className="Base-flexedCol-block" >
                <GiphySearch                    
                    search={searchStickers}
                    api={"giphy-stickers"}
                />
            </div>
            <div className="Base-flexedCol-block" >
                {sData.status === 1 ?
                    (sData.data.map((item, index) => {
                        return (
                            <GifFixed
                                gifMeta={{
                                    index,
                                    url: item.embed_url,
                                    height: parseInt(item.images.original.height, 10),
                                    width: parseInt(item.images.original.width, 10),
                                    link: item.bitly_url,
                                }}
                                onDisplay={onDisplay}
                            />
                        );
                    }
                    )) : (null)}
            </div>
        </div>
    );
}

//>>>>>>>>>> EXPORT <<<<<<<<<<//
export default (RightPanel);
