// index.js (src/components/Landing/LandingConsole)

//>>>>> PACKAGES <<<<<//
import React, { useState, useEffect } from "react";

//>>>>> FUNCTIONS <<<<<//
import { getTicker } from "../../../functions";

//>>>>> CONSTANTS <<<<<//
import { defaultTicker, tickerArray } from "../../../constants";

//>>>>> SASS STYLES <<<<<//
import "./styles.scss";

//>>>>> BASE COMPONENT FUNCTION  - LandingConsoleBase <<<<<//
function LandingConsole({ firebase }) {   

    //>>>>> Ticker Search <<<<<//  
    const [ticker, setTicker] = useState(tickerArray[0]);
    
    //>>>>> Realtime DB Query (Company Data) <<<<<//    
    const [tickerdata, setTickerData] = useState(false);
    useEffect(() => {
        const fetchTickerData = () => {
            if (!ticker || !firebase || !firebase.ticker) {
                setTickerData(false);
                return;
            } else {                
                // Query Database //
                getTicker(ticker, firebase.ticker, false)
                    .then((response) => {
                        setTickerData(response);
                    })
                    .catch((e) => {
                        console.error(e);
                        setTickerData({
                            ...defaultTicker,
                            ticker: ticker
                        });
                    });
            };             
        };
        fetchTickerData();
    }, [ticker, firebase]);   

    //>>>>> Quote Data Array <<<<<//
    const [consoledata, setConsoleData] = useState([]);
    useEffect(() => {
        const fetchAlphaData = async () => {
            if (!tickerdata) {
                return;
            } else {
                const url = {
                    myKey: "please",
                    myData:
                    {
                        api: "alpha-quote",
                        ticker: tickerdata.ticker,
                    }
                };
                const index = tickerArray.indexOf(tickerdata.ticker)
                await firebase.doCallAny(url)
                    .then(result => {
                        setConsoleData(prevState => {
                            let array = prevState;
                            const newQuote = result.data.myResp["Global Quote"];
                            let nextTile = {
                                ticker: tickerdata.ticker,
                                emoji: tickerdata.emoji,
                                open: newQuote['02. open'],
                                high: newQuote['03. high'],
                                low: newQuote['04. low'],
                                price: newQuote['05. price'],
                                volume: newQuote['06. volume'],
                                date: newQuote['07. latest trading day'],
                                prev: newQuote['08. previous close'],
                                change: newQuote['09. change'],
                                percent: newQuote['10. change percent'],
                            }
                            nextTile.price = nextTile.price.slice(0, nextTile.price.length - 2);
                            nextTile.change = nextTile.change.slice(0, nextTile.change.length - 2);
                            array[index] = nextTile;
                            return array;
                        });
                    })
                    .then(() => {
                        if (index < tickerArray.length) {
                            setTicker(tickerArray[index+1]);
                        } else { return };
                    })
                    .catch((e) => console.error(e));
                return null;
            };            
        };
        fetchAlphaData();
    }, [tickerdata]);
   
    //>>>>> DEBUG <<<<<//    
    //console.log("LandingConsole - ticker", ticker);
    //console.log("LandingConsole - tickerdata", tickerdata);
    //console.log("LandingConsole - consoledata", consoledata);
    //>>>>> DEBUG <<<<<//

    //>>>>> JSX RETURN <<<<<//
    return (
        <div
            id="LandingConsole-container"
            className="Base-block"
        >
            {consoledata.length > 0 ? (
            <div id="LandingConsole-content" >
                    <div
                        id="LandingConsole-marquee"
                        style={consoledata.length === tickerArray.length ?
                            { animation: "marquee-move 24s linear infinite" } :
                            { animation: "none" }}
                >
                {consoledata.map(item => {
                    return (
                        <div className="LandingConsole-quoteTile" >
                            <div className="LandingConsole-ticker" >
                                <a
                                    title={"Search Twitter - $" + item.ticker}
                                    href={"https://twitter.com/search?q=%24" + item.ticker + "&src=external"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                ><span role="img" >{item.emoji}</span>
                                </a>
                            </div>
                            <div className="LandingConsole-price" >
                                <span>{" " + item.price}</span>
                            </div>
                            <div
                                className="LandingConsole-change"
                                style={{ backgroundColor: (parseFloat(item.percent) >= 0 ? "#50d950" : "#f55656" ) }}
                            >
                                <span title="Change" >{item.change + " (" + (parseFloat(item.percent)).toFixed(2) + "%)"}</span>
                            </div>
                            <div className="LandingConsole-volume" >
                                <span title="Volume" >{"\uD83D\uDD0A" + item.volume}</span>
                            </div>
                        </div>
                    );
                })}
                </div>
            </div>
            ) : (null)}              
        </div>
        );    
    };

//>>>>> EXPORT <<<<<//
export default LandingConsole;
