// index.js (src/components/ChartNew/functions)

//>>>>> FUNCTION - formatVolumeTick <<<<<//
export const formatPriceTick = (tick) => {
    const value = parseFloat(tick);
    let fixedValue;
    value >= 10000 ? fixedValue = value.toFixed(0) : fixedValue = value.toFixed(2);
    return "\uFF04" + fixedValue;
};

//>>>>> FUNCTION - formatVolumeTick <<<<<//
export const formatVolumeTick = (tick) => {
    let outputTick = tick;
    if (tick > 10000000) {
        outputTick = (tick / 1000000).toFixed(1) + "*e6";
    };
    return outputTick;
};

//>>>>> FUNCTION - dateFormat <<<<<//
export const dateFormat = (date) => {
    let newDay = (date.getDate()).toString();
    let newMonth = (date.getMonth() + 1).toString();
    const newYear = date.getFullYear();
    if (newDay.length === 1) { newDay = "0" + newDay };
    if (newMonth.length === 1) { newMonth = "0" + newMonth };
    return newYear + "-" + newMonth + "-" + newDay;
};

export const sequenceDateAxisNew = (dataArray, setLength, sizeref, interval) => {

    const dateParams = {
        "TIME_SERIES_DAILY_ADJUSTED": {
            cutOff: [40, 200, 800, 0],
            trimStart: [0, 2, 10, 100],
            trimEnd: [0, setLength-2, setLength-10, setLength-100],
        },
        "TIME_SERIES_WEEKLY_ADJUSTED": {
            cutOff: [40, 160, 480, 0],
            trimStart: [0, 4, 12, 52],
            trimEnd: [0, setLength-4, setLength-12, setLength-52],
        },
        "TIME_SERIES_MONTHLY_ADJUSTED": {
            cutOff: [40, 160, 0],
            trimStart: [0, 4, 12],
            trimEnd: [0, setLength - 4, setLength - 12],
        },
        "TIME_SERIES_INTRADAY": {
            "1min": {
                cutOff: [0, 0, 0, 0, 0],
                trimStart: [0, 0, 0, 0, 0],
                trimEnd: [0, 0, 0, 0, 0],
            },
            "5min": {
                cutOff: [0, 0, 0, 0],
                trimStart: [0, 0, 0, 0],
                trimEnd: [0, 0, 0, 0],
            },
            "15min": {
                cutOff: [0, 0, 0, 0],
                trimStart: [0, 0, 0, 0],
                trimEnd: [0, 0, 0, 0],
            },
            "30min": {
                cutOff: [0, 0, 0],
                trimStart: [0, 0, 0],
                trimEnd: [0, 0, 0],
            },
            "60min": {
                cutOff: [0, 0],
                trimStart: [0, 0],
                trimEnd: [0, 0],
            }
        }
    };
    const lastIndex = setLength - 1;

    let outputArray = [];
    let filteredData = [];
    let m;
    //let filterLength;    

    switch (sizeref) {

        case "TIME_SERIES_DAILY_ADJUSTED":             
            if (setLength <= dateParams[sizeref].cutOff[0]) {
                // All Days
                filteredData = dataArray; 
            } else if (setLength <= dateParams[sizeref].cutOff[1]) {
                // First Day of the Week
                filteredData = dataArray.filter((item, index) =>
                    (item.weekstart === true && index > dateParams[sizeref].trimStart[1] &&
                        index < dateParams[sizeref].trimEnd[1]) || index === 0 || index === lastIndex);
            } else if (setLength <= dateParams[sizeref].cutOff[2]) {
                // First Day of the Month
                filteredData = dataArray.filter((item, index) =>
                    (item.monthstart === true && index > dateParams[sizeref].trimStart[2] &&
                        index < dateParams[sizeref].trimEnd[2]) || index === 0 || index === lastIndex); 
            } else {
                // First Day of the Year
                filteredData = dataArray.filter((item, index) =>
                    (item.yearstart === true && index > dateParams[sizeref].trimStart[2] &&
                        index < dateParams[sizeref].trimEnd[2]) || index === 0 || index === lastIndex); 
            }
            break;

        case "TIME_SERIES_WEEKLY_ADJUSTED": // DONE
            if (setLength <= dateParams[sizeref].cutOff[0]) {
                // All Weeks
                filteredData = dataArray; 
            } else if (setLength <= dateParams[sizeref].cutOff[1]) {
                // First Week of the Month
                filteredData = dataArray.filter((item, index) =>
                    (item.monthday < 8 && index > dateParams[sizeref].trimStart[1] &&
                        index < dateParams[sizeref].trimEnd[1]) || index === 0 || index === lastIndex);
            } else if (setLength <= dateParams[sizeref].cutOff[2]) {
                // First Week of the Quarter
                filteredData = dataArray.filter((item, index) =>
                    ((item.month === 0 || item.month === 3 || item.month === 6 || item.month === 9) &&
                        index > dateParams[sizeref].trimStart[2] && index < dateParams[sizeref].trimEnd[2]) ||
                        index === 0 || index === lastIndex);
            } else {
                // First Week of January
                filteredData = dataArray.filter((item, index) => (
                    (item.month === 0 && index > dateParams[sizeref].trimStart[3] &&
                        index < dateParams[sizeref].trimEnd[3]) || index === 0 || index === lastIndex));
            };
            break;

        case "TIME_SERIES_MONTHLY_ADJUSTED": // DONE
            if (setLength <= dateParams[sizeref].cutOff[0]) {
                // All Months
                filteredData = dataArray;
            } else if (setLength <= dateParams[sizeref].cutOff[1]) {
                // Jan, Apr, Jul, Oct
                filteredData = dataArray.filter((item, index) =>
                    ((item.month === 0 || item.month === 3 || item.month === 6 || item.month === 9) &&
                        index > dateParams[sizeref].trimStart[1] && index < dateParams[sizeref].trimEnd[1]) ||
                        index === 0 || index === lastIndex);
            } else {
                // January
                filteredData = dataArray.filter((item, index) => (item.month === 0 &&
                    index > dateParams[sizeref].trimStart[2] && index < dateParams[sizeref].trimEnd[2]) ||
                    index === 0 || index === lastIndex);
            };
            break;

        case "TIME_SERIES_INTRADAY":
            switch (interval) {
                case "1min":

                    if (setLength <= 40) {

                        filteredData = dataArray; 

                    } else if (setLength <= 200) {

                        filteredData = dataArray.filter(item => item.fivestart === true); 

                    } else if (setLength <= 600) {

                        filteredData = dataArray.filter(item => item.quarterstart === true); 

                    } else if (setLength <= 1200) {

                        filteredData = dataArray.filter(item => item.halfstart === true); 

                    } else {

                        filteredData = dataArray.filter(item => item.hourstart === true); 

                    };

                    break;

                case "5min":
                    //console.log("5min");
                    if (setLength <= 40) {
                        filteredData = dataArray; // all 5 minute
                    } else if (setLength <= 120) {
                        filteredData = dataArray.filter(item => item.quarterstart === true); // 1st 5 minute of the quarter hour
                    } else if (setLength <= 240) {
                        filteredData = dataArray.filter(item => item.halfstart === true); // 1st 5 minute of the half hour
                    } else {
                        filteredData = dataArray.filter(item => item.hourstart === true); // 1st 5 minute of the hour
                    };
                    break;
                case "15min":
                    //console.log("15min");
                    if (setLength <= 40) {
                        filteredData = dataArray; // all 15 minute
                    } else if (setLength <= 120) {
                        filteredData = dataArray.filter(item => item.halfstart === true); // 1st 15 minute of the half hour
                    } else if (setLength <= 240) {
                        filteredData = dataArray.filter(item => item.hourstart === true); // 1st 15 minute of the hour
                    } else {
                        filteredData = dataArray.filter(item => item.daystart === true); // 1st 15 minute of the day
                    };
                    break;
                case "30min":
                    //console.log("30min");
                    if (setLength <= 40) {
                        filteredData = dataArray; // all 30 minute
                    } else if (setLength <= 120) {
                        filteredData = dataArray.filter(item => item.hourstart === true); // 1st 30 minute of the hour
                    } else {
                        filteredData = dataArray.filter(item => item.daystart === true); // 1st 30 minute of the day
                    };
                    break;
                case "60min":
                    //console.log("60min");
                    if (setLength <= 40) {
                        filteredData = dataArray; // all 60 minute
                    } else {
                        filteredData = dataArray.filter(item => item.daystart === true); // 1st 60 minute of day
                    };
                    break;
                default:
                    console.error(dataArray, setLength, sizeref, interval);
            };
            break;

        default:
            console.error(dataArray, setLength, sizeref, interval);

    };
    const filterLength = filteredData.length;
    for (m = 0; m < filterLength; m++) {
        outputArray.push(filteredData[m]["date"].toString());
    };
    return outputArray;
};

//>>>>> FUNCTION - sequencePriceAxis <<<<<//
export const sequencePriceAxis = (data) => {
    // map data  
    const yKeys = Object.keys(data);
    const highSeries = yKeys.map(item => data[item]["high"]);
    const lowSeries = yKeys.map(item => data[item]["low"]);
    // find max, min, range in series
    const yMin = Math.min(...lowSeries);
    const yMax = Math.max(...highSeries);
    const yRange = yMax - yMin; // nominal range  
    // choose tick range
    const yTickEst = yRange / 9; // estimated tick space (10 ticks, 9 spaces over nominal)
    const tickSet = [.01, .02, .03, .04, .05, 0.1, 0.125, 0.25, .5, 1, 1.25, 2.5, 5, 10, 12.5, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000, 100000];
    let yTick = 0;
    let m;
    for (m = 0; m < tickSet.length; m++) {
        if (yTickEst >= tickSet[m] && yTickEst < tickSet[m + 1]) {
            yTick = tickSet[m];
        }
    };
    // setup the tick array
    const yAxisMin = (Math.ceil(yMin / yTick) * yTick) - (2 * yTick); // 2 ticks below min    
    const tickQty = Math.ceil(yRange / yTick) + 4; // 4 extra ticks over nomninal
    const yAxisMax = yAxisMin + ((tickQty - 1) * yTick);
    //console.log(tickQty, yRange, yTick);
    const mapArray = new Array(tickQty).fill().map((_, i) => i); // integer sequence array
    const yTickArray = mapArray.map(item => (yAxisMin + (item * yTick)).toFixed(2));
    return [yTickArray, yAxisMin, yAxisMax];
};

