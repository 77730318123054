// firebase.js (src/components/firebase)

//>>>>> IMPORT FIREBASE PACKAGE <<<<<//
import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";

const {
    //NODE_ENV,
    REACT_APP_ENV_FIREKEY,
    REACT_APP_ENV_AUTH,
    REACT_APP_ENV_DB,
    REACT_APP_ENV_PROJECT,
    REACT_APP_ENV_BUCKET,
    REACT_APP_ENV_SENDER,
    REACT_APP_ENV_APP,
    REACT_APP_ENV_MEASURE,
} = process.env;

//>>>>> API <<<<<//
const config = {
    apiKey: REACT_APP_ENV_FIREKEY,
    authDomain: REACT_APP_ENV_AUTH,
    databaseURL: REACT_APP_ENV_DB,
    projectId: REACT_APP_ENV_PROJECT,
    storageBucket: REACT_APP_ENV_BUCKET,
    messagingSenderId: REACT_APP_ENV_SENDER,
    appId: REACT_APP_ENV_APP,
    measurementId: REACT_APP_ENV_MEASURE,
};
// these are "Safe" per google refs but still vulernable to brute force

//>>>>> CLASS <<<<<//
class Firebase {

    ///// CONSTRUCTOR //////
    constructor() {

        // Initialize
        app.initializeApp(config); 
        // Firebase API"s
        this.auth = app.auth(); 
        this.db = app.database();  
        this.functions = app.functions();
        // Social Logins
        this.googleProvider = new app.auth.GoogleAuthProvider(); 
        this.facebookProvider = new app.auth.FacebookAuthProvider();
        this.twitterProvider = new app.auth.TwitterAuthProvider();
    }

    // Google Cloud Functions //    
    doCallAny = (data, context) => {
        const func = this.functions.httpsCallable("callAny");
        return func(data, context);
    }; // all external API calls 
    doCallAnyNew = (data, context) => {
        const func = this.functions.httpsCallable("callAnyNew");
        return func(data, context);
    }; // all external API calls (NEW)

    // email //
    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);
        // defined using the firebase api at auth.createUserWith...
    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    // social //
    doSignInWithGoogle = () =>
        this.auth.signInWithPopup(this.googleProvider);
    doSignInWithFacebook = () =>
        this.auth.signInWithPopup(this.facebookProvider);
    doSignInWithTwitter = () =>
        this.auth.signInWithPopup(this.twitterProvider);

    // account tools //
    doSignOut = () => this.auth.signOut();
    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);
    doSendEmailVerification = () =>  
        this.auth.currentUser.sendEmailVerification({
            url: process.env.REACT_APP_ENV_REDIRECT,
            //url: "https://www.ponzimemes.com",
        });
    

    //>>>>> User Database <<<<<//
    user = uid => this.db.ref(`users/${uid}`); // method to access specific user
    users = () => this.db.ref("users"); // for admin to access all

    //>>>>> Ticker Database <<<<<//
    ticker = (tick) => this.db.ref(`app/tickers/${tick}`); // query specific ticker
    tickers = () => this.db.ref("app/tickers"); // get entire set

    //>>>>> Search Database <<<<<//
    searchone = (cat) => this.db.ref(`app/search/${cat}`); // query specific category set 
    searches = () => this.db.ref("app/search") // get all searches
    
    //>>>>> AuthUser Construction <<<<<//
    onAuthUserListener = (next, fallback) =>      
        this.auth.onAuthStateChanged(authUser => {
            if (authUser) {                
                this.user(authUser.uid)
                    .once("value") 
                    .then(snapshot => {
                        const dbSnapshot = snapshot.val();
                        // this syntax is confusing because this part of the
                        // firebase api can listent continually ".on("value")" or once
                        // ".once("value") says make a snapshot available of everything
                        // below this path. snapshot.val() grabs the data
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            ...dbSnapshot,
                        };
                        // there are more parameters available under authUser
                        next(authUser);
                    });
            } else {
                fallback();
                // should returns null when authUser is null fallback(authUser)
            };
        });   

}

export default Firebase;

