// index.js (src/components/ChartNew/LeftPanel/MyTileBoard)

//>>>>>>>>>> PACKAGES <<<<<<<<<<//
import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";

//>>>>>>>>>> COMPONENTS <<<<<<<<<<//
import GifFlip from "./GifFlip";

//>>>>>>>>>> SASS STYLES <<<<<<<<<<//
import "./styles.scss";

//>>>>>>>>>> COMPONENT FUNCTION <<<<<<<<<<//
function GifPanel({ onDisplay, gData, yData}) {        

    //>>>>>>>>>> Tile Array <<<<<<<<<<//
    const [tilearray, setTileArray] = useState({ status: 0, data: []});
    useEffect(() => {       
        const updateData = () => {

            if (!gData || !yData) {
                return null;
            } else {

                switch (yData.status) {
                    case 0:
                        break;
                    case 1:
                        if (gData.status === 1) {
                            let tileArray = yData.data.map((item, index) => {
                                const googleItem = gData.data[index];
                                let row = [];
                                let thumb = "../../../img/giphy.png";
                                if ("pagemap" in googleItem) {
                                    if ("cse_image" in googleItem.pagemap) { thumb = googleItem["pagemap"]["cse_image"][0]["src"] };
                                };
                                row.push(
                                    {
                                        gifEmbed: item.embed_url,
                                        yHeight: parseInt(item.images.original.height, 10),
                                        yWidth: parseInt(item.images.original.width, 10),
                                        yLink: item.bitly_url,
                                        yTitle: item.title,
                                        ySource: item.source_post_url,
                                        gLink: googleItem["link"],
                                        gTitle: googleItem["title"],
                                        gThumb: thumb,
                                    }
                                );
                                return row;
                            });
                            setTileArray({ status: 1, data: tileArray });   
                        } else {
                            break;
                        };
                        break;
                    default:
                        break;
                };

                return null;

            };       
        };
        updateData();
    },[gData, yData]);    

    //>>>>>>>>>> JSX Return <<<<<<<<<<//
    return (
        <div
            id="MyTileBoard-container"
            className="Base-block-flexCol"            
        >
            {tilearray.status===1 ? (
                <div className="Base-flexedCol-flexCol" >
                    {tilearray.data.map(item => (
                        <GifFlip
                            //yUrl={item[0]["gifEmbed"]}
                            //yHeight={item[0]["yHeight"]}
                            //yWidth={item[0]["yWidth"]}
                            //yLink={item[0]["yLink"]}
                            //yTitle={item[0]["yTitle"]}
                            //ySource={item[0]["ySource"]}
                            //gLink={item[0]["gLink"]}
                            //gTitle={item[0]["gTitle"]}
                            //gThumb={item[0]["gThumb"]}
                            gifMeta={{
                                yUrl: item[0]["gifEmbed"],
                                yHeight: item[0]["yHeight"],
                                yWidth: item[0]["yWidth"],
                                yLink: item[0]["yLink"],
                                yTitle: item[0]["yTitle"],
                                ySource: item[0]["ySource"],
                                gLink: item[0]["gLink"],
                                gTitle: item[0]["gTitle"],
                                gThumb: item[0]["gThumb"],
                            }}
                            onDisplay={onDisplay}
                        />
                    ))}
                </div>
            ) : (
                <div className="Base-flexedCol-block" >
                    <Loader
                        type="Grid"
                        color="#de7c9a"
                        height={100}
                        width={100}
                        timeout={10000}
                    />
                </div>
                )}
        </div>
    );    
};

//>>>>>>>>>> EXPORT <<<<<<<<<<//
export default GifPanel;

