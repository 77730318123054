// Title index.js (src/components/ChartNew/ChartOutput)

//>>>>> PACKAGES <<<<<//
import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import {
    LineChart,
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Line, 
    Bar,
    Tooltip,    
    ResponsiveContainer,
} from "recharts";
import { useMeasure } from "react-use";

//>>>>> REACT COMPONENTS <<<<<//
import CustomTimeTick from "./CustomTimeTick";
import Candle from "./Candle";
import NoDot from "./NoDot";
import ChartGif from "./ChartGif";
import LineTip from "./LineTip";
import BarTip from "./BarTip";
import InfoSheet from "./InfoSheet";

//>>>>> FUNCTIONS <<<<<//
import * as FUNC from "./functions";

//>>>>> DATA <<<<<//
import { initSettingsReducer } from "../../../constants";

//>>>>> SASS STYLES <<<<<//
import "./styles.scss";

//>>>>> COMPONENT - ChartOutput <<<<<//
function ChartOutput(props) {

    //>>>>> 1 - Div Sizing UseRefs <<<<<//
    const [sizeRef, { width, height }] = useMeasure();

    //>>>>> 2 - Props to State Hook <<<<<// 
    const [mystate, setMyState] = useState(false);
    useEffect(() => {        
        // Function Definition //
        const updateMyState = (props) => {     
            // Break Condition //
            if (!props) return;  
            // Set Hook //
            setMyState(props);     
        };
        // Function Trigger //
        updateMyState(props);
    }, [props]);   //dependency
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //
    //
    // **Objective**
    // Set hooks to props
    // 
    // **Comments**
    // - use this as the template for basic props to hook
    // - read some ideas that would involve 
    // - work status codes back from here after children are done
    // - for now adding status codes to state seems very redundant and complex
    // - status codes are for tracking async prop values, beyond that is overkill
    // - can run some testing on mystate values after build
    //   
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //

    //>>>>> 3 - Separate and Validata Data Props <<<<<//  
    const [alphadata, setAlphaData] = useState({ status: 0, data: {} });
    const [chartmeta, setChartMeta] = useState({ status: 0, data: {} });
    const [settings, setSettings] = useState(initSettingsReducer);
    useEffect(() => {
        const updateAlphaData = () => {
            // Startup Break
            if (!mystate || !mystate.aData) {
                setAlphaData(
                    {
                        status: 0,
                        data: {}
                    }
                );
                return;
            } else {
                switch (mystate.aData.status) {
                    case 0:
                        // startup
                        setAlphaData(
                            {
                                status: 0,
                                data: {}
                            }
                        );                        
                        break;
                    case 1:
                        // fresh data
                        setAlphaData(
                            {
                                status: 1,
                                data: mystate.aData.data
                            }
                        );
                        break;
                    case 110:
                        // recycled data
                        setAlphaData(
                            {
                                status: 1,
                                data: mystate.aData.data
                            }
                        );
                        break;
                    case 400:
                        // loading
                        setAlphaData(
                            {
                                status: 400,
                                data: {}
                            }
                        );
                        break;
                    default: 
                        // error catch
                        setAlphaData(
                            {
                                status: 900,
                                data: {}
                            }
                        );
                };          
            };                
        };
        const updateMeta = () => {
            // Break Conditions //
            if (!mystate || !mystate.chartMeta) {
                setChartMeta(
                    {
                        status: 0,
                        data: {}
                    }
                );
                return;
            } else {
                switch (mystate.chartMeta.status) {
                    case 0:
                        setChartMeta(
                            {
                                status: 0,
                                data: {}
                            }
                        );
                        return
                    case 1:
                        setChartMeta(
                            {
                                status: 1,
                                data: mystate.chartMeta.data
                            }
                        );
                        break;
                    default:
                        setChartMeta(
                            {
                                status: 900,
                                data: {}
                            }
                        );

                };

            };
        };
        const updateSettings = () => {
            // Break Conditions //
            if (!mystate || !mystate.chartSettings) {
                return;
            } else {
                setSettings(mystate.chartSettings);
            };  
            // no status codes here, this is a side effect reducer
        };
        updateAlphaData();
        updateMeta();
        updateSettings();

    }, [mystate]);
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //
    //
    // **Objective**
    // - Deconstruct props and set hooks for chart data and page meta data
    // 
    // **Comments**
    // - still can"t figure out "!" origin, only in build version or firebase 
    // - 
    //
    // **Status Codes - alphadata**
    // 0: startup
    // 1: ready
    // 400: mystate.aData false, falsy or ! 
    //
    // **Status Codes - chartmeta**
    // 0: startup
    // 1: ready
    // 400: mystate.chartMeta falsy
    //   
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //

    //>>>>> 4 - Process Dates <<<<<// 
    const [dates, setDates] = useState({ status: 0, data: {} });
    useEffect(() => {
        const updateDates = () => {

            if (chartmeta.status === 0) {
                setDates({ status: 0, data: {} });
                return;
            } else if (chartmeta.status === 1) {

                let checkBegin = new Date(chartmeta.data.dateStart + " 01:00:00 GMT-0400");
                let checkEnd = new Date(chartmeta.data.dateEnd + " 01:00:00 GMT-0400");
                const beginDay = checkBegin.getDay();
                const endDay = checkEnd.getDay();
                // 0:Sunday 6:Saturday
                // this default time is only used for weekend check   
                // ignore clock time coming from user input here

                if (beginDay === 0 || beginDay === 6 || endDay === 0 || endDay === 6) {
                    // Start Date (calendar only) //
                    switch (beginDay) {
                        // Sunday //
                        case 0:
                            checkBegin.setTime(checkBegin.getTime() + 86400000);
                            break;
                        // Saturday //
                        case 6:
                            checkBegin.setTime(checkBegin.getTime() - 86400000);
                            break;
                        default:
                            break;
                    };
                    // End Date (calendar only) //
                    switch (endDay) {
                        // Sunday //
                        case 0:
                            checkEnd.setTime(checkEnd.getTime() + 86400000);
                            break;
                        // Saturday //
                        case 6:
                            checkEnd.setTime(checkEnd.getTime() - 86400000);
                            break;
                        default:
                            break;
                    };
                };
                // rewrote this way because the most common case is !condition            

                // Call Date Formatter
                const newStartDate = FUNC.dateFormat(checkBegin);
                const newEndDate = FUNC.dateFormat(checkEnd);

                // Create Date Object - "YYYY-MM-DD" format with clock //
                const datesOut = {
                    dateStart: newStartDate,
                    dateEnd: newEndDate,
                    clockStart: chartmeta.data.clockStart,
                    clockEnd: chartmeta.data.clockEnd,
                    database: chartmeta.data.database,
                    subSet: chartmeta.data.subSet,
                    emoji: chartmeta.data.emoji,
                    ticker: chartmeta.data.ticker,
                };

                // Set Hook //
                setDates({ status: 1, data: datesOut });             

            } else {
                if (chartmeta.status === 400) {
                    setDates({ status: 400, data: {} });
                } else {
                    // no known code
                    setDates({ status: 900, data: chartmeta.data });
                    console.error(chartmeta.data);
                };
                
            };

        };
        updateDates();
    }, [chartmeta]);
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //
    //
    // **Objective**
    // - Create chartmeta object for dates, clock etc. used here and children
    // 
    // **Comments**
    // - mixing of title and date data is to limit number of hooks. all meta here
    //
    // **Status Codes**
    // 0: startup
    // 1: ready
    // 400: falsy dependency
    // 900: unrecognized status code in dependency
    //   
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //

    //>>>>> 5 - Process Data <<<<<//
    const [rechartdata, setRechartData] = useState({ status: 0, data: {} });
    
    useEffect(() => {

        const updateRechartData = () => {

            if (alphadata.status === 0 && dates.status === 0) {
                setRechartData({ status: 0, data: {} });
                return;
            } else if (alphadata.status !== 1 && dates.status === 1) {
                setRechartData({ status: 101, data: {} });
                // this is the case where the dates and meta are all there
                // but chart data is still processing
                // this could be animation, placeholder, etc.
                // anything to make it go faster
                return;
            } else if (alphadata.status === 1 && dates.status === 0) {
                setRechartData({ status: 102, data: {} });
                // dates always finish first so this wont run much if ever
                return;
            } else if (alphadata.status === 400 || dates.status === 400) {
                setRechartData({ status: 400, data: {} });
                return;
            } else if (alphadata.status === 1 && dates.status === 1) {

                // Variable Declaration for Switch Cases //
                let startDateIndex;
                let endDateIndex;
                let finalOutput;
                let slicedKeys;
                let slicedData;
                let formatOutput;

                // Get Keys & Length //     
                const dataKeys = Object.keys(alphadata.data);
                const dataLength = dataKeys.length;

                // Fix AV Object Type //
                const alphaArray = Array.from(Object.values(alphadata.data));

                // Search Data Keys (not in date format)
                if (dates.data.database === "TIME_SERIES_INTRADAY") {
                    endDateIndex = dataKeys.findIndex((item, index) => {
                        return item === dates.data.dateEnd + dates.data.clockEnd;
                    });
                    startDateIndex = dataKeys.findIndex(item => item === dates.data.dateStart + dates.data.clockStart);
                    //console.log("index search - intraday", dates.data.dateEnd + dates.data.clockEnd, dates.data.dateStart + dates.data.clockStart);
                } else {
                    endDateIndex = dataKeys.findIndex(item => item === dates.data.dateEnd);
                    startDateIndex = dataKeys.findIndex(item => item === dates.data.dateStart);
                };
                //console.log("initial index search - before fix", endDateIndex, startDateIndex);

                // Date and Time for Searching
                const endDateFull = dates.data.database === "TIME_SERIES_INTRADAY" ?
                    new Date(dates.data.dateEnd + dates.data.clockEnd + " GMT-0400") : new Date(dates.data.dateEnd + " 09:00:00 GMT-0400");
                const startDateFull = dates.data.database === "TIME_SERIES_INTRADAY" ?
                    new Date(dates.data.dateStart + dates.data.clockStart + " GMT-0400") : new Date(dates.data.dateStart + " 09:00:00 GMT-0400");
                const endDateMs = endDateFull.getTime();
                const startDateMs = startDateFull.getTime();

                // Slice Data //   
                switch (dates.data.database) {

                    // All Intraday Cases //
                    case "TIME_SERIES_INTRADAY":
                        //console.log("Start Case A - All Intraday");
                        //console.log("initial indices - fixed", endDateIndex, startDateIndex);
                        if (endDateIndex === -1) {
                            let j;
                            let loopDateFull;
                            for (j = 0; j < dataLength; j++) {
                                loopDateFull = new Date(dataKeys[j]);
                                if (loopDateFull <= endDateMs) {
                                    endDateIndex = j;
                                    break;
                                };
                                if (j === dataLength - 1) {
                                    endDateIndex = j;
                                    break;
                                };
                            };
                        };
                        if (startDateIndex === -1) {
                            let j;
                            let loopDateFull;

                            for (j = 0; j < dataLength; j++) {
                                loopDateFull = new Date(dataKeys[j]);
                                if (loopDateFull <= startDateMs) {
                                    startDateIndex = j;
                                    break;
                                };
                                if (j === dataLength - 1) {
                                    startDateIndex = j;
                                    break;
                                };
                            };
                        };
                        // GOAL -> wrap all of the date searching into an imported function
                        // getIndex(keys, start, end) (WIP)
                        // this has problem re: the time zone switch, dataKeys are NY time

                        switch (endDateIndex) {

                            case 0:

                                // NEW (replace inner switch case)
                                //console.log("Case AA-1");
                                // Case: (0, **startDateIndex**)
                                // Note: endDateIndex is only 0 when both the end date and
                                //       clock match the last quote in the set. Depending on
                                //       AV data and my clock setting this could happen alot
                                //       but more likely its only *very close* to this case
                                if (startDateIndex === 0 || startDateIndex === dataKeys.length - 1 || startDateIndex > 480) {
                                    startDateIndex = 480;
                                };
                                slicedKeys = dataKeys.slice(0, startDateIndex + 1);
                                slicedData = alphaArray.slice(0, startDateIndex + 1);
                                break;

                            case dataKeys.length - 1:

                                //console.log("Case AA-2");
                                // Case: (last (earliest) quote, **startDateIndex**)
                                // Action: Default slice regardless of endIndex
                                // Note: User would have to be asking for a start date that 
                                //       exactly matches the earliest quote in the set 
                                // Freq: almost impossible
                                slicedKeys = dataKeys.slice(0, 481);
                                slicedData = alphaArray.slice(0, 481);
                                break;

                            default:
                                //console.log("Case AA-Def");
                                // Case: (somewhere in the set, **startDateIndex**)
                                switch (startDateIndex) {

                                    // Case: [somewhere in the set, first (most recent) quote]
                                    // Action: Flip the indices, slice from EndDate to latest
                                    // Note: User is asking for starting date that matches the
                                    //       most recent quote, with an end date before it
                                    //       Pre-empt with Yup validation?
                                    // Freq: both rare and has dates backwards
                                    case 0:
                                        //console.log("Case AA-Def-a");
                                        slicedKeys = dataKeys.slice(0, endDateIndex + 1);
                                        slicedData = alphaArray.slice(0, endDateIndex + 1);
                                        break;

                                    // Case: [somewhere in the set, last (oldest) quote]
                                    // Action: Slice everything more recent than endDateIndex
                                    // Note: User nailed the first data point in the set
                                    //       exactly. This means 2nd parameter of slice not
                                    //       required.
                                    // Freq: rare but not impossible
                                    case dataKeys.length - 1:
                                        //console.log("Case AA-Def-b");
                                        slicedKeys = dataKeys.slice(endDateIndex);
                                        slicedData = alphaArray.slice(endDateIndex);
                                        break;

                                    // Case: [in in the set, in the set]
                                    // Action: Slice as is
                                    // Note: Need a few validations for the special case 
                                    //       of the dates matching exactly, or being reversed
                                    // Freq: This should theoretically be the most common
                                    default:
                                        if (endDateIndex < startDateIndex) { // Case: both in the set somewhere, not ends
                                            //console.log("Case AA-Def-Def-1");
                                            slicedKeys = dataKeys.slice(endDateIndex, startDateIndex + 1);
                                            slicedData = alphaArray.slice(endDateIndex, startDateIndex + 1);
                                        } else if (endDateIndex === startDateIndex) { // Case: picks same exact quote
                                            //console.log("Case AA-Def-Def-2");
                                            slicedKeys = dataKeys.slice(endDateIndex, endDateIndex + 240)
                                            slicedData = alphaArray.slice(endDateIndex, endDateIndex + 240);
                                            // note: this could trip if they tried to pick a day close to the back end
                                            //       add something for this later to cut it off at the end                                    
                                        } else if (endDateIndex > startDateIndex) { // Case: inside set but dates out of order
                                            //console.log("Case AA-Def-Def-3");
                                            slicedKeys = dataKeys.slice(startDateIndex, endDateIndex + 1);
                                            slicedData = alphaArray.slice(startDateIndex, endDateIndex + 1);
                                        } else {
                                            console.error(startDateIndex, endDateIndex);
                                            slicedKeys = dataKeys.slice(0, 240);
                                            slicedData = alphaArray.slice(0, 120);
                                        };
                                };
                        };
                        break;

                    // All Interday Cases //
                    default:

                        // Date Indices //
                        //console.log("Start Case B - All Interday");

                        // Missing Date Cleanup //    
                        if (endDateIndex === -1) {
                            let j;
                            let loopDateFull;
                            for (j = 0; j < dataLength; j++) {
                                loopDateFull = new Date(dataKeys[j]);
                                if (loopDateFull <= endDateMs) {
                                    endDateIndex = j;
                                    break;
                                };
                                if (j === dataLength - 1) {
                                    startDateIndex = j;
                                    break;
                                };
                            };
                        };
                        if (startDateIndex === -1) {
                            let j;
                            let loopDateFull;
                            for (j = 0; j < dataLength; j++) {
                                loopDateFull = new Date(dataKeys[j]);
                                if (loopDateFull <= startDateMs) {
                                    startDateIndex = j;
                                    break;
                                };
                                if (j === dataLength - 1) {
                                    startDateIndex = j;
                                    break;
                                };
                            };
                        };

                        // Outer Switch by End Date (startIndex) //
                        switch (endDateIndex) { // this is the end date

                            // end date (start index) matches most recent data point
                            case 0:

                                //console.log("Case B-1");  
                                if (startDateIndex === 0 || startDateIndex === dataKeys.length - 1) {
                                    startDateIndex = 120;
                                } else if (startDateIndex > 505) { startDateIndex = 505 };
                                slicedKeys = dataKeys.slice(0, startDateIndex + 1);
                                slicedData = alphaArray.slice(0, startDateIndex + 1);

                                break;

                            // end date (start index) matches oldest data point (uncommon)
                            case dataKeys.length - 1:

                                //console.log("Case B-2");
                                slicedKeys = dataKeys.slice(dataLength - 50);
                                slicedData = alphaArray.slice(dataLength - 50);
                                break;
                            // this one can be expanded but wtf is the user doing
                            // send to default like they want the beginning of the set                          

                            // end date (start index) somehwere in the set, not the end
                            default:

                                //console.log("Case B-def")
                                // Inner Switch //
                                switch (startDateIndex) {

                                    case 0:
                                        //console.log("Case B-Def-a");
                                        slicedKeys = dataKeys.slice(0, endDateIndex + 1);
                                        slicedData = alphaArray.slice(0, endDateIndex + 1);
                                        break;
                                    // Case: (any other integer, 0)
                                    // Action: reverse the dates
                                    // Note: could maybe catch this with input validation
                                    //       this case the use has end before begin                                
                                    case dataKeys.length - 1:
                                        //console.log("Case B-Def-b");
                                        slicedKeys = dataKeys.slice(endDateIndex);
                                        slicedData = alphaArray.slice(endDateIndex);
                                        break;
                                    // Case: (any other integer, oldest point)
                                    // Action: slice startIndex to end
                                    // Note: rare case bc user would have picked start date 
                                    //       that matched the av data set start point                                    
                                    default:
                                        //console.log("Case B-Def-Def");
                                        if (endDateIndex < startDateIndex) { // Case: both in the set somewhere, not ends
                                            //console.log("Case B-Def-Def-1");
                                            slicedKeys = dataKeys.slice(endDateIndex, startDateIndex + 1);
                                            slicedData = alphaArray.slice(endDateIndex, startDateIndex + 1);
                                        } else if (endDateIndex === startDateIndex) { // Case: picks same day, inside set
                                            //console.log("Case B-Def-Def-2");
                                            slicedKeys = dataKeys.slice(endDateIndex, endDateIndex + 20)
                                            slicedData = alphaArray.slice(endDateIndex, endDateIndex + 20);
                                            // note: this could trip if they tried to pick a day close to the back end
                                            //       add something for this later to cut it off at the end                                    
                                        } else if (endDateIndex > startDateIndex) { // Case: inside set but dates out of order
                                            //console.log("Case B-Def-Def-3");
                                            slicedKeys = dataKeys.slice(startDateIndex, endDateIndex + 1);
                                            slicedData = alphaArray.slice(startDateIndex, endDateIndex + 1);
                                        } else {
                                            console.error(startDateIndex, endDateIndex);
                                            slicedKeys = dataKeys.slice(0, 240);
                                            slicedData = alphaArray.slice(0, 120);
                                        };
                                };
                        };
                };

                // variables for switch     
                let date;
                let factor;
                let array;
                let openPrice;
                let closePrice;
                let highPrice;
                let lowPrice;
                let currentMinute;
                let hourStart = 99;
                let dayStart = 99;
                let weekStart = 99;
                let monthStart = 99;
                let yearStart = 999;
                // need to update this as tagging first item is now redundant
                // start at 0? TBD low priority

                // Format Sliced Data
                switch (dates.data.database) {

                    case "TIME_SERIES_INTRADAY":
                        //console.log("Final Recharts - Intraday");                      

                        formatOutput = slicedData.map(function (row, index) {
                            date = new Date(slicedKeys[index] + " GMT-0400");
                            openPrice = parseFloat(row["1. open"], 10);
                            closePrice = parseFloat(row["4. close"], 10) // no factor (close is adjusted)
                            highPrice = parseFloat(row["2. high"], 10);
                            lowPrice = parseFloat(row["3. low"], 10);
                            currentMinute = date.getMinutes();
                            const output = {
                                open: openPrice,
                                close: closePrice,
                                high: highPrice,
                                low: lowPrice,
                                volume: parseFloat(row["5. volume"], 10),
                                ...(closePrice >= openPrice ? { up: "green" } : { up: "red" }),
                                range: highPrice - lowPrice,
                                delta: Math.abs(closePrice - openPrice),
                                date: slicedKeys[index],
                                datefull: date,
                                datenum: date.getTime(),
                                weekday: date.getDay(),
                                hour: date.getHours(),
                                ...(currentMinute % 5 === 0 ? { fivestart: true } : { fivestart: false }),
                                ...(currentMinute % 15 === 0 ? { quarterstart: true } : { quarterstart: false }),
                                ...(currentMinute === 0 || currentMinute === 30 ? { halfstart: true } : { halfstart: false }),
                            }
                            return output;
                        });

                        // Sort //
                        formatOutput.reverse();

                        // Date Axis Triggers //
                        finalOutput = formatOutput.map(row => {
                            array = row;
                            hourStart !== array.hour ? array.hourstart = true : array.hourstart = false;
                            dayStart !== array.weekday ? array.daystart = true : array.daystart = false;
                            hourStart = array.hour;
                            dayStart = array.weekday;
                            return array;
                        });
                        break;

                    default:
                        //console.log("Final Recharts - Interday");                       
                        formatOutput = slicedData.map(function (row, index, array) {
                            date = new Date(slicedKeys[index]);
                            factor = parseFloat(row["5. adjusted close"], 10) / parseFloat(row["4. close"], 10);
                            openPrice = factor * parseFloat(row["1. open"], 10);
                            closePrice = parseFloat(row["5. adjusted close"], 10) // no factor (close is adjusted)
                            highPrice = factor * parseFloat(row["2. high"], 10);
                            lowPrice = factor * parseFloat(row["3. low"], 10);
                            return (
                                {

                                    open: openPrice,
                                    close: closePrice,
                                    high: highPrice,
                                    low: lowPrice,
                                    volume: parseFloat(row["6. volume"], 10),
                                    ...(closePrice >= openPrice ? { up: "green" } : { up: "red" }),
                                    range: highPrice - lowPrice,
                                    delta: Math.abs(closePrice - openPrice),
                                    date: slicedKeys[index],
                                    datefull: date,
                                    datenum: date.getTime(),
                                    weekday: date.getDay(),
                                    monthday: date.getDate(),
                                    month: date.getMonth(),
                                    year: date.getYear(),
                                }
                            );
                        });

                        // Sort //
                        formatOutput.reverse();

                        // Date Axis Triggers //
                        finalOutput = formatOutput.map(row => {
                            array = row;
                            weekStart > array.weekday ? array.weekstart = true : array.weekstart = false;
                            monthStart !== array.month ? array.monthstart = true : array.monthstart = false;
                            yearStart !== array.year ? array.yearstart = true : array.yearstart = false;
                            weekStart = array.weekday;
                            monthStart = array.month;
                            yearStart = array.year;
                            return array;
                        });
                };

                // svg parameters
                const setLength = finalOutput.length;

                // Chart Title //
                const outputTitle = {
                    ...(!dates.data.emoji ? { full: "$" + dates.data.ticker } : { full: dates.data.emoji }),
                    tick: "$" + dates.data.ticker
                };
                // If there is no emoji, default to displaying the actual ticker     
                // This works for items in the db with no emoji AND items not in the db

                // price axis //
                let [yTickArray, yAxisMin, yAxisMax] = FUNC.sequencePriceAxis(finalOutput);

                // new date axis //
                const xTickArray = FUNC.sequenceDateAxisNew(finalOutput, setLength, dates.data.database, dates.data.subSet);

                let rechartdataOut = {
                    rcData: finalOutput,
                    yAxis:
                    {
                        yTickArray: yTickArray,
                        yMin: yAxisMin,
                        yMax: yAxisMax
                    },
                    xAxis:
                    {
                        xTickArray: xTickArray
                    },
                    ...(setLength <= 80 ? { rcGrid: true } : { rcGrid: false }),
                    rcTitle: outputTitle,
                };
                setRechartData({ status: 1, data: rechartdataOut });

            } else {
                setRechartData({ status: 900, data: [dates, alphadata] });
                console.error(dates, alphadata);
            }   
        };
        updateRechartData();

    }, [alphadata, dates]);
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //
    //
    // **Objective**
    // - Process all data. Slice, format, sort, etc.
    // - initialize scale values for chart
    // 
    // **Comments**
    // -  
    //
    // **Status Codes - rechartdata**
    // 0: startup
    // 1: ready
    // 900: unknown code error  
    //
    // **Status Codes - chartscale**
    // 0: startup
    // 1: ready
    // 900: unknown code error  
    //   
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //

    //>>>>> 6A - Live Dimensions <<<<<//
    const [livedims, setLiveDims] = useState({ status: 0, data: { height: 0, width: 0 } });
    useEffect(() => {
        const updateDims = () => {
            if (!mystate || !rechartdata || !height || !width) {
                return;
            } else if (!mystate.chartAspect || rechartdata.status !== 1 || height === 0 || width === 0 ) {
                setLiveDims({ status: 0, data: { height: 0, width: 0 } });
            } else {
                const setLength = rechartdata.data.rcData.length;
                const range = rechartdata.data.yAxis.yMax - rechartdata.data.yAxis.yMin;

                // candle width (in pixels)
                const xScale = (0.97 * (width - 80)) / setLength;
                // Padding Factor - 
                // Resp Container Factor - 0.98 set in <ResponsiveContainer />
                // Y Axis width - 80px (fixed in recharts <YAxis /> properties)
                // X Axis padding - disabled for testing

                // candle height scale (in pixels per dollar)
                const yScale = ((1 / mystate.chartAspect) * (xScale * setLength)) / range;    
                // still not clear on how recharts measures the aspect
                
                setLiveDims({ status: 1, data: { height: yScale, width: xScale } });
            };
        };
        updateDims();
    }, [height, width, rechartdata, mystate]);    
   
    //>>>>> DEBUG <<<<<//
    // Hooks //
    //console.log("props - ChartOutput", props);
    //console.log("mystate - ChartOutput", mystate);
    //console.log("alphadata - ChartOutput", alphadata);
    //console.log("chartmeta - ChartOutput", chartmeta);
    //console.log("settings - ChartOutput", settings);
    //console.log("dates - ChartOutput", dates);
    //console.log("rechartdata - ChartOutput", rechartdata);   
    //console.log("dims", height, width);
    //console.log("livedims - ChartOutput", livedims);

    //>>>>> Return <<<<<//
    return (
        <div id="ChartOutput-container" >
            {rechartdata.status === 1 ? (
                <div
                    id="ChartOutput-content"
                    ref={sizeRef}
                >
                    <div id="ChartOutput-header" >
                        <div id="ChartOutput-clearGifs" >
                            {mystate.cGifs.length > 0 ? (
                                <button                                    
                                    onClick={() => mystate.onDisplay({type:"clear-gifs", data: null})}
                                ><span>CLEAR</span></button>
                            ) : (null)}
                        </div>
                        <div id="ChartOutput-tickLink" >
                            <a                               
                                title="Search Twitter"                                
                                href={"https://twitter.com/search?q=%24" + rechartdata.data.rcTitle.tick + "&src=external"}
                                target="_blank"
                                rel="noopener noreferrer"
                            ><span>{rechartdata.data.rcTitle.full}</span></a>
                        </div>
                        <div id="ChartOutput-screen" >
                            <button                                                           
                                onClick={() => mystate.onDispatch({ type: "change-view", data: "left"})}
                            ><span>{"\u2B05"}</span></button>
                            <button                                
                                onClick={() => mystate.onDispatch({ type: "change-view", data: "full" })}
                            ><span>{"\u2194"}</span></button>
                            <button                                                            
                                onClick={() => mystate.onDispatch({ type: "change-view", data: "reset" })}
                            ><span>{"\u21A9"}</span></button>
                            <button                                                                
                                onClick={() => mystate.onDispatch({ type: "change-view", data: "right" })}
                            ><span>{"\u27A1"}</span></button>
                        </div>                        
                    </div>  
                    
                    <div                        
                        className="ChartOutput-recharts"                                             
                    >
                        <ResponsiveContainer                            
                            width="98%"
                            aspect={mystate.chartAspect}
                            minWidth={420}
                        >
                            <LineChart
                                data={rechartdata.data.rcData}
                                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                            >         
                                <XAxis  
                                    tick={false}
                                    padding={{ left: 15, right: 15 }}
                                />
                                <YAxis
                                    type="number"
                                    domain={[dataMin => rechartdata.data.yAxis.yMin, dataMax => rechartdata.data.yAxis.yMax]}    
                                    tickFormatter={FUNC.formatPriceTick}
                                    ticks={rechartdata.data.yAxis.yTickArray}                                    
                                    allowDataOverflow={true}
                                    tickSize={4}
                                    width={80}
                                    tick={{
                                        fontSize: "1.4em",
                                        fontFamily: "'Righteous', Courier, monospace",
                                        stroke: "#063554"
                                    }}
                                />
                                <CartesianGrid
                                    horizontal={true}
                                    vertical={rechartdata.data.rcGrid}
                                    stroke="#0e004d"
                                    strokeDasharray="5 5"
                                    strokeOpacity="0.1"
                                />
                                <Tooltip
                                    content={<LineTip />}
                                    isAnimationActive={true}
                                    animationBegin={200}
                                    animationDuration={500}
                                    animationEasing="linear"
                                />
                                <Line
                                    type="linear"
                                    dataKey="open"
                                    stroke={settings.open.color}
                                    strokeWidth={settings.open.status ? 2 : 0}   
                                    dot={<NoDot />}
                                    isAnimationActive={false}
                                    name="Open"
                                />   
                                <Line
                                    type="linear"
                                    dataKey="close"
                                    stroke={settings.close.color}                                 
                                    strokeWidth={settings.close.status ? 2 : 0}   
                                    dot={settings.symbol.status && livedims.status === 1 ? (<Candle svgScale={livedims.data} />) : <NoDot />}
                                    //dot={<NoDot />}
                                    isAnimationActive={false}
                                    name="Close"
                                />
                                <Line
                                    type="linear"
                                    dataKey="high"
                                    stroke={settings.high.color}   
                                    strokeWidth={settings.high.status ? 2 : 0}
                                    dot={<NoDot />}
                                    isAnimationActive={false}
                                    name="High"
                                />
                                <Line
                                    type="linear"
                                    dataKey="low"
                                    stroke={settings.low.color}   
                                    strokeWidth={settings.low.status ? 2 : 0}
                                    dot={<NoDot />}
                                    isAnimationActive={false}
                                    name="Low"
                                />                         
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
               
                    <div
                        className="ChartOutput-recharts"                        
                    >
                        <ResponsiveContainer
                            width="98%"
                            aspect={3.5*mystate.chartAspect}
                            minWidth={420}
                        >
                            <BarChart
                                data={rechartdata.data.rcData}
                            >
                                <CartesianGrid
                                    strokeDasharray="5 5"
                                    horizontal={true}
                                    vertical={false}
                                />
                                <XAxis
                                    dataKey="date"
                                    tick={<CustomTimeTick myTicks={rechartdata.data.xAxis.xTickArray} />}
                                    tickSize={2}
                                    interval={0}
                                    height={80}
                                    //padding={{ left: 25, right: 25 }}
                                />
                                <YAxis                                    
                                    tickFormatter={FUNC.formatVolumeTick}
                                    tickSize={4}
                                    width={80}
                                    tick={{
                                        fontSize: "1.4em",
                                        fontFamily: "'Righteous', Courier, monospace",
                                        stroke: "#063554"
                                    }}
                                />
                                <Tooltip
                                    content={<BarTip />}
                                    isAnimationActive={true}
                                    animationBegin={200}
                                    animationDuration={500}
                                    animationEasing="linear"
                                />
                                <Bar
                                    dataKey="volume"
                                    fill="#01852d"
                                    isAnimationActive={true}
                                    animationDuration={500}
                                    name="Volume"
                                />
                            </BarChart>
                        </ ResponsiveContainer>
                    </div>                   
                    {mystate.cGifs.length > 0 ? (
                        <div
                            id="ChartOutput-gifs"
                            className="Base-flexedCol-flexRow"
                        >
                            {mystate.cGifs.map((item, index) => (
                                <div
                                    id="ChartOutput-ChartGif"
                                    className="Base-flexedRow-block"
                                >
                                    <ChartGif
                                        gifMeta={{
                                            url: item.url,
                                            height: item.height,
                                            width: item.width,
                                            index: index
                                        }}
                                        onDisplay={props.onDisplay}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (null)}
                    <div id="ChartOutput-InfoSheet" >
                        <InfoSheet
                            metaData={chartmeta}
                            qData={mystate.qData}
                        />
                    </div>
                </div>     
            ) : (
                <div
                    id="ChartOutput-loader"
                    className="Base-block"
                >
                    <Loader
                        type="Grid"
                        color="#81c8f7"
                        height={360}
                        width={480}
                        timeout={8000}                     
                    />                    
                </div>
            )}
    </div>
    );
};

export default (ChartOutput);


