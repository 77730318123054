// withAuthorization.js (src/components/Session)

import React from 'react';
import { compose } from 'recompose';
import { navigate } from 'hookrouter';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthorization = condition => Component => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            this.listener = this.props.firebase.onAuthUserListener(
                authUser => {
                    if (!condition(authUser)) {                        
                        navigate('/');
                    }
                },               
                () => navigate('/'),
            );            
        }
        
        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                <AuthUserContext.Consumer>
                    {authUser =>
                        condition(authUser) ?
                            <Component {...this.props} authUser={authUser} /> : null                    
                    }
                </AuthUserContext.Consumer>
            );
        }
    }

    return compose(        
        withFirebase,
    )(WithAuthorization);
};

export default withAuthorization;