// Title: index.js (src/components/Account)

//>>>>> PACKAGES <<<<<//
import React from "react";
import { compose } from "recompose";

//>>>>> SESSION <<<<<//
import {
    withAuthentication,
    withAuthorization,
    withEmailVerification
} from "../Session";

//>>>>> LOCAL COMPONENTS <<<<<//
import PasswordChange from "./PasswordChange";

//>>>>> SCSS STYLES <<<<<//
import "./styles.scss";

//>>>>> BASE COMPONENT <<<<<//
const Account = ({ authUser, firebase }) => {
    
    //>>>>> JSX Return <<<<<//
    return (
        <div id="Account-container">
            <div className="Base-flexedCol-flexCol">
                <h3>Account Info</h3>
                <p>{"Email: " + authUser.email}</p>
                <p>{"Username: " + authUser.username}</p>            
            </div>
            <div
                className="Base-flexedCol-block"
                style={{ maxWidth: "400px" }}
            >
                <h3>Password Change</h3>
                <PasswordChange
                    firebase={firebase}
                />
            </div>
        </div>
    );
};

//>>>>> EXPORTED COMPONENT WITH CONTEXT <<<<<//
const AccountPage = ({ authUser, firebase }) => {
    return(
        <div className="Base-block" >
            <Account
                firebase={firebase}
                authUser={authUser}                                
            />
        </div>
        );
};

//>>>>> AUTHORIZATION STATE <<<<<//
const condition = authUser => !!authUser;

//>>>>> RECOMPOSE EXPORT <<<<<//
export default compose(
    withAuthentication,
    withEmailVerification,
    withAuthorization(condition),
)(AccountPage);

