// dateSetup.js (src/data)

let rightNow = new Date();
let newDay = (rightNow.getDate()).toString();
let newMonth = (rightNow.getMonth() + 1).toString();
let newYear = rightNow.getFullYear();
if (newDay.length === 1) { newDay = "0" + newDay };
if (newMonth.length === 1) { newMonth = "0" + newMonth };

export const todaysDate = newYear + "-" + newMonth + "-" + newDay;     

export const startDate = "2020-12-07";
