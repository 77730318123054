// index.js (src/components/Landing)

//>>>>> PACKAGES <<<<<//
import React, { useReducer } from "react";
import { compose } from "recompose";

//>>>>> SESSION <<<<<//
import {
    AuthUserContext,
    withAuthentication
} from "../Session";

//>>>>> LOCAL <<<<<//
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import PasswordForget from "./PasswordForget";
import SignInGoogle from "./SignInGoogle";
import LandingConsole from "./LandingConsole";
import SiteMap from "./SiteMap";

//>>>>> CONSTANTS <<<<<//
import { initLandDisp } from "../../constants";

//>>>>> SASS STYLES <<<<<//
import "./styles.scss";

//>>>>> DISPLAY REDUCER FUNCTION <<<<<//
function displayReducer(initState, action) {
    let newState;
    switch (action) {
        case "signUp":
            newState = {
                signUp: !initState.signUp,
                forgotPw: initState.forgotPw
            };
            return newState;
        case "forgotPw":
            newState = {
                signUp: initState.signUp,
                forgotPw: !initState.forgotPw
            };
            return newState;
        default:
            console.error(initState, action);
    };
};

//>>>>> BASE COMPONENT <<<<<//
const Landing = ({ firebase }) => {

    //>>>>> Display Reducer <<<<<//
    const [display, dispatchDisplay] = useReducer(displayReducer, initLandDisp);

    //>>>>> JSX RETURN <<<<<//
    return (
        <div id="Landing-container" >         
            <div id="Landing-LandingConsole" >
                <LandingConsole
                    firebase={firebase}
                />
            </div>           
            <div id="Landing-main" >
                <div id="Landing-logo" >                
                    <p>{"\u20BD"}</p>
                </div>      
                <div id="Landing-consumer">
                    <AuthUserContext.Consumer>
                    {authUser =>
                        authUser ? (
                        <div id="Landing-auth" >
                            <div id="Landing-SiteMap" >
                                <SiteMap
                                    authUser={authUser}
                                />
                            </div>
                            <div id="Landing-welcome" >
                                <p>{"Welcome Back " + authUser.username + "!"}</p>
                            </div>
                        </div>
                        ) : (
                        <div id="Landing-noauth" >
                            <div id="Landing-intro" >
                                <p>{"Welcome to \u20BDonzimemes"}</p>
                                <p id="Landing-message">{"Stock Charts for your Inner Child"}</p>
                                <iframe src="https://giphy.com/embed/ioopmOHLqIDfGxLLKG" ></iframe>
                            </div>
                            <div id="Landing-login" >
                                <div className="Base-flexedCol-block" >
                                    <h3>LOGIN</h3>
                                </div>
                                <div className="Base-flexedCol-block" >
                                    <SignIn
                                        firebase={firebase}
                                        onDispatch={(e) => { dispatchDisplay(e) }}
                                    />
                                </div>
                                <div className="Base-flexedCol-block" >
                                    {display.forgotPw ? (
                                    <PasswordForget
                                        firebase={firebase}
                                    />
                                    ) : (null)}
                                </div>
                                <div className="Base-flexedCol-flexCol" >
                                    <h4>LOGIN WITH</h4>
                                </div>
                                <div
                                    id="Landing-social"
                                    className="Base-flexedCol-block"
                                >
                                    <SignInGoogle
                                        firebase={firebase}
                                    />
                                </div>
                                <div className="Base-flexedCol-block" >
                                    <h4>USE EMAIL ADDRESS</h4>
                                    <button
                                        className="Landing-bigButton"
                                        onClick={() => { dispatchDisplay("signUp") }}
                                    >SIGN UP</button>
                                </div>
                                {display.signUp ? (
                                <div className="Base-flexedCol-block" >
                                    <SignUp
                                        firebase={firebase}
                                    />
                                </div>
                                ) : (null)}
                            </div>
                        </div>
                        )}
                    </AuthUserContext.Consumer>    
                </div>           
            </div>
        </div>
    );
};

//>>>>> EXPORTED COMPONENT WITH CONTEXT <<<<<//
const LandingPage = ({ firebase }) => {
    return (            
        <div className="Base-block" >
            <Landing
                firebase={firebase}                
            />
        </div>   
    );
};


//>>>>> RECOMPOSE EXPORT <<<<<//
export default compose(
    withAuthentication
)(LandingPage);

