// Title: index.js (src/components/ChartNew/ChartOutput/ChartGif/GifPopup)

//>>>>> PACKAGES <<<<<//
import React from "react";
import { Knob } from "react-rotary-knob";
import * as skins from "react-rotary-knob-skin-pack";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

//>>>>> SASS STYLES <<<<<//
import "./styles.scss";

//>>>>> COMPONENT - GifAdjust <<<<<//
function GifPopup(props) {

    const knobS = {
        width: "5em",
        height: "5em",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0",
        margin: "0",
    };

    //console.log(props);

    return (

        <Popup
            trigger={
                <button className="ChartGif-button" >
                    <span role="img" >{"\uD83D\uDD89"}</span>
                </button>
            }
            position="bottom center"
            arrow={true}
            offsetX={0}
            offsetY={0}
        >
            <div className="GifPop-container" >            
                <div className="Base-flexedCol-flexRow" >
                    <div className="Base-flexedRow-flexRow" >
                        <div className="GifPop-emoji" >
                            <span>{"\uD83D\uDD0E"}</span>
                        </div>
                        <div
                            title="Resize"
                            className="GifPop-Knob-wrap"                           
                        >
                            <Knob
                                style={knobS}
                                value={props.gifSettings.width}
                                onChange={(val) => props.onDispatch({name: "width", value: val})}
                                //onStart={() => { }}
                                //onEnd={() => { }}
                                min={120}
                                max={720}
                                step={40}
                                unlockDistance={30}
                                //clampMin={}
                                //clampMax={}
                                //rotateDegrees={}
                                skin={skins.s11}
                            />
                        </div>
                    </div>
                    <div className="Base-flexedRow-flexRow" >
                        <div className="GifPop-emoji" >
                            <span>{"\uD83D\uDD26"}</span>
                        </div>
                        <div
                            title="Opacity"
                            className="GifPop-Knob-wrap"   
                        >
                            <Knob
                                style={knobS}
                                value={props.gifSettings.clear / 0.01}
                                onChange={(val) => props.onDispatch({ name: "clear", value: 0.01 * val })}
                                min={10}
                                max={100}
                                step={10}
                                unlockDistance={30}
                                skin={skins.s11}
                            />
                        </div>
                    </div>
                </div>
                <div className="Base-flexedCol-flexRow" >
                    <div className="Base-flexedRow-flexRow" >
                        <div className="GifPop-emoji" >
                            <span>{"\uD83D\uDD04Z"}</span>
                        </div>
                        <div
                            title="Rotate"
                            className="GifPop-Knob-wrap"
                        >
                            <Knob
                                style={knobS}
                                value={props.gifSettings.angle}
                                onChange={(val) => props.onDispatch({ name: "angle", value: val })}
                                min={0}
                                max={360}
                                step={30}
                                unlockDistance={30}
                                skin={skins.s11}
                            />
                        </div>
                    </div>
                    <div className="Base-flexedRow-flexRow" >
                        <div className="GifPop-emoji" >
                            <span>{"\u2300"}</span>
                        </div>
                        <div
                            title="Radius"
                            className="GifPop-Knob-wrap"
                        >
                            <Knob
                                style={knobS}
                                value={props.gifSettings.radius}
                                onChange={(val) => props.onDispatch({ name: "radius", value: val })}
                                min={0}
                                max={50}
                                step={10}
                                unlockDistance={30}
                                skin={skins.s11}
                            />
                        </div>
                    </div>
                </div>
                <div className="Base-flexedCol-flexRow" >
                    <div className="Base-flexedRow-flexRow" >
                        <div className="GifPop-emoji" >
                            <span>{"\uD83D\uDD04X"}</span>
                        </div>
                        <div
                            title="SpinX"
                            className="GifPop-Knob-wrap"
                        >
                            <Knob
                                style={knobS}
                                value={props.gifSettings.spinX}
                                onChange={(val) => props.onDispatch({ name: "spinX", value: val })}
                                min={0}
                                max={360}
                                step={30}
                                unlockDistance={30}
                                skin={skins.s11}
                            />
                        </div>
                    </div>
                    <div className="Base-flexedRow-flexRow" >
                        <div className="GifPop-emoji" >
                            <span>{"\uD83D\uDD04Y"}</span>
                        </div>
                        <div
                            title="SpinY"
                            className="GifPop-Knob-wrap"
                        >
                            <Knob
                                style={knobS}
                                value={props.gifSettings.spinY}
                                onChange={(val) => props.onDispatch({ name: "spinY", value: val })}
                                min={0}
                                max={360}
                                step={30}
                                unlockDistance={30}
                                skin={skins.s11}
                            />
                        </div>
                    </div>
                </div>

                <div className="Base-flexedCol-flexRow" >
                    <div className="Base-flexedRow-flexRow" >
                        <div
                            className="GifPop-emoji"
                            style={{transform: "skewX(-15deg)"}}
                        >
                            <span>{"\uD83D\uDFE9"}</span>
                        </div>
                        <div
                            title="SkewX"
                            className="GifPop-Knob-wrap"
                        >
                            <Knob
                                style={knobS}
                                value={props.gifSettings.skewX}
                                onChange={(val) => props.onDispatch({ name: "skewX", value: val })}
                                min={-60}
                                max={60}
                                step={10}
                                unlockDistance={30}
                                skin={skins.s11}
                            />
                        </div>
                    </div>
                    <div className="Base-flexedRow-flexRow" >
                        <div
                            className="GifPop-emoji"
                            style={{ transform: "skewY(-15deg)" }}
                        >
                            <span>{"\uD83D\uDFE9"}</span>
                        </div>
                        <div
                            title="SkewY"
                            className="GifPop-Knob-wrap"
                        >
                            <Knob
                                style={knobS}
                                value={props.gifSettings.skewY}
                                onChange={(val) => props.onDispatch({ name: "skewY", value: val })}
                                min={-60}
                                max={60}
                                step={10}
                                unlockDistance={30}
                                skin={skins.s11}
                            />
                        </div>
                    </div>                    
                </div>   
                <div className="Base-flexedCol-flexRow" >
                    <div className="Base-flexedRow-flexRow" >
                        <div
                            className="GifPop-emoji"
                        >
                            <span>{"\uD83D\uDD06"}</span>
                        </div>
                        <div
                            title="Contrast"
                            className="GifPop-Knob-wrap"
                        >
                            <Knob
                                style={knobS}
                                value={props.gifSettings.contrast}
                                onChange={(val) => props.onDispatch({ name: "contrast", value: val })}
                                min={0.0}
                                max={2.0}
                                step={0.1}
                                unlockDistance={30}
                                skin={skins.s11}
                            />
                        </div>
                    </div>
                    <div className="Base-flexedRow-flexRow" >
                        <div
                            className="GifPop-emoji"
                        >
                            <span>{"\uD83C\uDF08"}</span>
                        </div>
                        <div
                            title="Hue"
                            className="GifPop-Knob-wrap"
                        >
                            <Knob
                                style={knobS}
                                value={props.gifSettings.hue}
                                onChange={(val) => props.onDispatch({ name: "hue", value: val })}
                                min={0}
                                max={360}
                                step={30}
                                unlockDistance={30}
                                skin={skins.s11}
                            />
                        </div>
                    </div>
                </div>
                <div className="Base-flexedCol-flexRow" >
                    <div className="Base-flexedRow-flexRow" >
                        <div
                            className="GifPop-emoji"
                        >
                            <span>{"\u25FE\u25FD"}</span>
                        </div>
                        <div
                            title="Grayscale"
                            className="GifPop-Knob-wrap"
                        >
                            <Knob
                                style={knobS}
                                value={props.gifSettings.gray}
                                onChange={(val) => props.onDispatch({ name: "gray", value: val })}
                                min={0.0}
                                max={1.0}
                                step={0.1}
                                unlockDistance={30}
                                skin={skins.s11}
                            />
                        </div>
                    </div>
                    <div className="Base-flexedRow-flexRow" >
                        <div
                            className="GifPop-emoji"
                        >
                            <span>{"\uD83D\uDC53"}</span>
                        </div>
                        <div
                            title="Blur"
                            className="GifPop-Knob-wrap"
                        >
                            <Knob
                                style={knobS}
                                value={props.gifSettings.blur}
                                onChange={(val) => props.onDispatch({ name: "blur", value: val })}
                                min={0}
                                max={20}
                                step={1}
                                unlockDistance={30}
                                skin={skins.s11}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
     );
}

export default (GifPopup);
