// index.js (src/components/App)

//>>>>> PACKAGES <<<<<//
import React from "react";
import { useRoutes, A } from "hookrouter";
import { compose } from "recompose";

//>>>>> SESSION <<<<<//
import {
    AuthUserContext,
    withAuthentication
} from "../Session";

//>>>>> LOCAL <<<<<//
import LandingPage from "../Landing";
import ChartPage from "../Chart";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import SignOutButton from "../SignOut";

//>>>>> LOCAL MEDIA IMPORT <<<<<//
import ponziThumb from "../../img/ponzi36.png";

//>>>>> SCSS STYLES <<<<<//
import "./styles.scss";

//>>>>> ROUTES <<<<<//
const hookRoutes = {
    "/": () => <LandingPage />,
    "/chart": () => <ChartPage />,    
    "/account": () => <AccountPage />,    
    "/admin" : () => <AdminPage />,     
};

//>>>>> COMPONENT <<<<<//
const App = () => {

    //>>>>> HOOKROUTER <<<<<//
    const routeResult = useRoutes(hookRoutes);

    //>>>>> JSX RETURN <<<<<//
    return (
        <div id="App-container" >  
            <div id="App-header" >
                <AuthUserContext.Consumer>
                    {authUser =>
                        authUser ? (
                        <div
                            id="App-header-auth"
                            className="Base-block-flexRow"
                        >
                            <div
                                className="Base-flexedRow-flexRow"
                                id="App-header-authLinks"
                            >
                                <A
                                    className="App-link"
                                    title="Home"
                                    href="/"
                                >
                                    <img
                                        className="App-img"
                                        alt="Chuck"
                                        src={ponziThumb}
                                    />
                                </A>
                                <A
                                    className="App-link"
                                    title="Charts"
                                    href="/chart"
                                >
                                    <span
                                        className="App-emoji"
                                        role="img"
                                    >{"\uD83D\uDCC8"}</span>
                                </A>
                                <A
                                    className="App-link"
                                    title="Account"
                                    href="/account"
                                >
                                    <span role="img" >{"\uD83D\uDCBC"}</span>
                                </A>
                                {!!authUser.roles.ADMIN && (
                                    <A
                                        className="App-link"
                                        title="Admin"
                                        href="/admin"
                                    >
                                        <span role="img" >{"\uD83D\uDD27"}</span>
                                    </A>
                                )}
                            </div>
                            <div
                                id="App-SignOut"
                                className="Base-flexedRow-block"
                                title="Sign Out"
                            >
                                <SignOutButton />
                            </div>
                            <div className="App-logo" >
                                <span>{"\u20BDonzimemes"}</span>
                            </div>
                        </div>
                        ) : (
                        <div
                            id="App-header-noauth"
                            className="Base-block-flexRow"
                        >
                            <div
                                className="Base-flexedRow-flexRow"
                            >
                                <A
                                    className="App-link"
                                    title="Home"
                                    href="/"
                                >
                                    <img
                                        className="App-img"
                                        alt="Chuck"
                                        src={ponziThumb}
                                    />
                                </A>
                            </div>
                            <div className="App-logo" >
                                <span>{"\u20BDonzimemes"}</span>
                            </div>
                        </div>
                        )}
                </AuthUserContext.Consumer>
            </div>

            <div
                className="Base-flexedCol-flexCol"
                id="App-router"
            >
                {routeResult || <LandingPage />}
            </div> 
            
            <div
                className="Base-flexedCol-flexRow"
                id="App-footer"
            >         
                <span
                    id="App-span-img"
                    alt="Ponzi"
                    role="img"  
                >
                    <img
                        className="App-img"
                        alt="Chuck"
                        src={ponziThumb}
                    />
                </span>                   
                <span id="App-span-tm" >{"\u20BDonzimemes\u2122 2020 All Rights Reserved"}</span>               
            </div>

        </div>        
    );    
};

//>>>>> EXPORTED COMPONENT WITH CONTEXT <<<<<//
const AppPage = () => {
    return (
        <div className="Base-block" >
            <App />
        </div>
    );
};

//>>>>> RECOMPOSE EXPORT <<<<<//
export default compose(
    withAuthentication
)(AppPage);



