// Title: index.js (src/components/Chart)

//>>>>>>>>>> NPM PACKAGES <<<<<<<<<<//
import React, { useState, useEffect, useRef, useReducer } from "react";
import { compose } from "recompose";
import { useMeasure } from "react-use";

//>>>>>>>>>> REACT COMPONENTS <<<<<<<<<<//
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import ChartOutput from "./ChartOutput";

//>>>>>>>>>> FUNCTIONS <<<<<<<<<<//
import {
    getTicker,
    getSearch
} from "../../functions";

//>>>>>>>>>> CONSTANTS <<<<<<<<<<//
import {
    startupMeta,
    startupInput,
    aUrlDefault,
    gUrlDefault,
    gifSearchDefault,
    defaultSearchArray,
    qUrlDefault,
    reqTypeSet,    
    initSettingsReducer,
    initDisplayReducer
} from "../../constants";

//>>>>>>>>>> AUTHENTICATION <<<<<<<<<<//
import {
    withAuthentication,
    withAuthorization,
    withEmailVerification
} from "../Session";

//>>>>>>>>>> CALENDAR <<<<<<<<<<//
import * as DATES from "../../data/dateSetup";

//>>>>>>>>>> SCSS STYLES <<<<<<<<<<//
import "./styles.scss";

//>>>>>>>>>> RECHART REDUCER SETUP <<<<<<<<<<//
function settingsReducer(initState, action) {
    let newState;
    switch (action.type) {        
        case "toggle":
            newState = {
                ...initState,
                [action.direction.name]: {
                    status: !initState[action.direction.name].status,
                    color: initState[action.direction.name].color,
                }
            };
            return newState;
        case "color":
            newState = {
                ...initState,
                [action.direction.name]: {
                    status: initState[action.direction.name].status,
                    color: action.direction.context,
                }
            };
            return newState;
        default:
            newState = {
                ...initState
            };
            console.log("Switch Case Error", initState, action);
            return newState;          
    };
};
//>>>>>>>>>> DISPLAY REDUCER SETUP <<<<<<<<<<//
function displayReducer(initState, action) {
    let newState;
    let newArray;
    switch (action.type) {
        case "change-view":
            switch (action.data) {
                case "left":
                    newState = {
                        ...initState,
                        view: {
                            left: !initState.view.left,
                            right: initState.view.right
                        }
                    };
                    return newState;
                case "right":
                    newState = {
                        ...initState,
                        view: {
                            left: initState.view.left,
                            right: !initState.view.right
                        }
                    };
                    return newState;
                case "reset":
                    newState = {
                        ...initState,
                        view: {
                            left: true,
                            right: true
                        }
                    };
                    return newState;
                case "full":
                    newState = {
                        ...initState,
                        view: {
                            left: false,
                            right: false
                        }
                    };
                    return newState;
                default:
                    newState = {
                        ...initState
                    };
                    console.log("Switch Case Error", initState, action);
                    return newState;
            }
        case "add-gif":
            newArray = initState.gifs;
            newArray.push(action.data);
            newState = {
                ...initState,
                gifs: newArray
            }
            return newState
        case "remove-gif":
            newArray = initState.gifs;
            newArray.splice(action.data.index, 1);
            newState = {
                ...initState,
                gifs: newArray
            };
            return newState;
        case "clear-gifs":
            newState = {
                ...initState,
                gifs: []
            };
            return newState;
        default:
            newState = {
                ...initState
            };
            console.log("Switch Case Error", action);
            return newState;
    };
};

//>>>>>>>>>> BASE COMPONENT FUNCTION <<<<<<<<<<//
function Chart({ authUser, firebase }) {

    //>>>>>>>>>> useRef 1 - API Query Ref <<<<<<<<<<//
    let aUrlRef = useRef(""); 

    //>>>>>>>>>> useRef 2 - Ticker Metadata Cache <<<<<<<<<<//
    let tickerRef = useRef({});

    //>>>>>>>>>> useRef 3 - Aspect Ratio <<<<<<<<<<//
    let chartAspect = useRef(2.1);

    //>>>>>>>>>> Hook 1 - Aspect Ratio <<<<<<<<<<//
    const [sizeRef, { width }] = useMeasure();    
    useEffect(() => {
        const updateAspect = () => {
            if (!width || width === 0) {
                return;
            } else {
                if (width > 1400 && chartAspect.current !== 2.1) {
                    chartAspect.current = 2.1;
                } else if (width > 1000 && chartAspect.current !== 1.8) {
                    chartAspect.current = 1.8;
                } else if (width <= 1000 && chartAspect.current !== 1.6) {
                    chartAspect.current = 1.6;
                };
            };
        };
        updateAspect();
    }, [width]);
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //
    //
    // **Objective**
    // - Update the chart aspect useRef with the useMeasure hook
    //
    // **Status Codes - NONE**
    //
    // **Comments**
    // - 
    //
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //    

    //>>>>>>>>>> Hook 2 - User Ticker, Dates, Interval <<<<<<<<<<//
    const [input, setInput] = useState({
        status: 101,
        data: {
            search: "SPY",
            dateStart: DATES.startDate,
            dateEnd: DATES.todaysDate,
            clockStart: " 09:30:00",
            clockEnd: " 16:00:00",
            interval: 0,
        }
    });
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //
    //
    // **Objective**
    // Set the user input to initialize API call sequences. useState sets the initial
    // search.
    //
    // **Status Codes - input**
    // 0: not used 
    // 1: validated form input, new or cached ticker
    // 101: default preset startup
    // **111: user preset setup (NEW)
    // 400: not used
    // 900: not used 
    // 
    // **Comments**
    // - Starts on status 101/111, 0 not used
    // - 4XX/9XX not used because Yup does all validation
    //
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //

    //>>>>>>>>>> Hooks 3:7 - Search Hooks <<<<<<<<<<//
    const [aurl, setAurl] = useState({ status: 0, data: {} });
    const [gurl, setGurl] = useState({ status: 0, data: {} });
    const [gifsearch, setGifSearch] = useState({ status: 0, data: {} });
    const [chartmeta, setChartMeta] = useState({ status: 0, data: {} });
    const [qurl, setQurl] = useState({ status: 0, data: {} });
    useEffect(() => {
        const parseInput = () => {
            // New Switch Syntax //
            if (!input) {
                return;
            } else {
                switch (input.status) {
                    case 101:
                        // Cache
                        tickerRef.current[startupMeta.ticker] = { ...startupMeta };
                        // Alpha - startup //
                        setAurl({
                            status: 1,
                            data: aUrlDefault
                        });
                        // Google - startup //
                        setGurl({
                            status: 1,
                            data: gUrlDefault
                        });
                        // Giphy - startup  //
                        setGifSearch({
                            status: 1,
                            data: gifSearchDefault
                        });
                        // ChartOutput Parameters - startup  //
                        setChartMeta({
                            status: 1,
                            data: {
                                dateStart: DATES.startDate,
                                dateEnd: DATES.todaysDate,
                                ...reqTypeSet[1],
                                ...startupMeta,
                                ...startupInput
                            }
                        });
                        // InfoSheet Quote - startup  //
                        setQurl({status: 1, data: qUrlDefault });
                        break;
                    case 1:
                        // Interval //  
                        let reqType;
                        const seriesDuration = ((Date.parse(input.data.dateEnd)) -
                            (Date.parse(input.data.dateStart))) / 86400000;
                        if (input.data.interval >= 1 && input.data.interval <= 8) {
                            reqType = reqTypeSet[input.data.interval];
                        } else {
                            if (seriesDuration >= 5 && seriesDuration < 180) {
                                reqType = reqTypeSet[1];
                            } else if (seriesDuration >= 3 && seriesDuration < 5) {
                                reqType = reqTypeSet[8];
                            } else if (seriesDuration >= 1 && seriesDuration < 3) {
                                reqType = reqTypeSet[7];
                            } else if (seriesDuration >= 0.5 && seriesDuration < 1) {
                                reqType = reqTypeSet[6];
                            } else if (seriesDuration >= 0.25 && seriesDuration < 0.5) {
                                reqType = reqTypeSet[5];
                            } else if (seriesDuration < 0.25) {
                                reqType = reqTypeSet[4];
                            } else if (seriesDuration >= 180 && seriesDuration < 720) {
                                reqType = reqTypeSet[2];
                            } else if (seriesDuration >= 720) {
                                reqType = reqTypeSet[3];
                            } else {
                                reqType = reqTypeSet[1];
                                console.error(input);
                            };
                        };
                        // Note: haven"t found any faster way to do range, can't use switch

                        if (input.data.search in tickerRef.current) {
                            // Alpha - cached //
                            setAurl(
                                {
                                    status: 1,
                                    data:
                                    {
                                        api: "alpha-set",
                                        ticker: input.data.search,
                                        db: reqType.database,
                                        size: reqType.setSize,
                                        ...(reqType.subSet ?
                                            { sub: "&interval=" + reqType.subSet } :
                                            { sub: "" })
                                    }
                                });
                            // Google - cached  //
                            setGurl(
                                {
                                    status: 1,
                                    data:
                                    {
                                        api: "google-links",
                                        search: tickerRef.current[input.data.search].tickdisplay,
                                        qty: 10
                                    }
                                });
                            // Giphy - cached //
                            setGifSearch(
                                {
                                    status: 1,
                                    data: {
                                        main: tickerRef.current[input.data.search].sector1,
                                        sub: tickerRef.current[input.data.search].sector2,
                                        detail: tickerRef.current[input.data.search].sector3
                                    }
                                });
                            // ChartOutput Parameters - cached //
                            setChartMeta(
                                {
                                    status: 1,
                                    data: {
                                        fromDb: true,
                                        ...tickerRef.current[input.data.search],
                                        ...reqType,
                                        ...input.data
                                    }
                                });
                            // InfoSheet Quote - cached  //
                            setQurl(
                                {
                                    status: 1,
                                    data:
                                    {
                                        api: "alpha-quote",
                                        ticker: input.data.search,
                                    }
                                });
                        } else {

                            //>> Call Firebase Ticker .then(Set Hooks) <<//            
                            getTicker(input.data.search, firebase, reqType)
                                .then((response) => {
                                    const subSet = response.subSet ? "&interval=" +
                                        response.subSet : "";
                                    const metaOut = {
                                        ...response,
                                        ...input.data,
                                    };

                                    // Cache
                                    let trimResponse = { ...response };
                                    // use spread operator otherwise it will alter the
                                    // "response" variable also
                                    delete trimResponse.database;
                                    delete trimResponse.setSize;
                                    delete trimResponse.subSet;
                                    // these keys are included in the response object
                                    // delete them so tickerRef matches realtime db object
                                    // then add it to the cache object
                                    tickerRef.current[response.ticker] = trimResponse;

                                    // Alpha //
                                    setAurl(
                                        {
                                            status: 1,
                                            data:
                                            {
                                                api: "alpha-set",
                                                ticker: response.ticker,
                                                db: response.database,
                                                size: response.setSize,
                                                sub: subSet,
                                            }
                                        });
                                    
                                    // Google //
                                    setGurl(
                                        {
                                            status: 1,
                                            data:
                                            {
                                                api: "google-links",
                                                search: response.tickdisplay,
                                                qty: 10
                                            }
                                        });
                                    // Giphy //
                                    setGifSearch({
                                        status: 1,
                                        data: {
                                            main: response.sector1,
                                            sub: response.sector2,
                                            detail: response.sector3
                                        }
                                    });
                                    // ChartOutput Parameters //
                                    setChartMeta({ status: 1, data: metaOut });
                                    // InfoSheet Quote //
                                    setQurl(
                                        {
                                            status: 1,
                                            data:
                                            {
                                                api: "alpha-quote",
                                                ticker: response.ticker,
                                            }
                                        });
                                })
                                .catch((error) => {
                                    console.log("error", error);
                                    // Alpha - error default //
                                    setAurl({
                                        status: 1,
                                        data: aUrlDefault
                                    });
                                    // Google - error default //
                                    setGurl({
                                        status: 1,
                                        data: gUrlDefault
                                    });
                                    // Giphy - error default  //
                                    setGifSearch({
                                        status: 1,
                                        data: gifSearchDefault
                                    });
                                    // ChartOutput Parameters - error default  //
                                    setChartMeta({
                                        status: 1,
                                        data:
                                        {
                                            dateStart: DATES.startDate,
                                            dateEnd: DATES.todaysDate,
                                            ...reqTypeSet[1],
                                            ...startupMeta,
                                            ...startupInput
                                        }
                                    });
                                    // InfoSheet Quote - error default  //
                                    setQurl({ status: 1, data: qUrlDefault });
                                });
                        };
                        break;
                    default:
                        console.log("Error", input);
                        // Alpha - error default //
                        setAurl({
                            status: 1,
                            data: aUrlDefault
                        });
                        // Google - error default //
                        setGurl({
                            status: 1,
                            data: gUrlDefault
                        });
                        // Giphy - error default  //
                        setGifSearch({
                            status: 1,
                            data: gifSearchDefault
                        });
                        // ChartOutput Parameters - error default  //
                        setChartMeta({
                            status: 1,
                            data:
                            {
                                dateStart: DATES.startDate,
                                dateEnd: DATES.todaysDate,
                                ...reqTypeSet[1],
                                ...startupMeta,
                                ...startupInput
                            }
                        });
                        // InfoSheet Quote - error default  //
                        setQurl({ status: 1, data: qUrlDefault });
                        break;
                };
            };
        };
        parseInput();
    }, [input]);
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //
    //
    // **Objective**
    // After user initiates search set all API and chart hooks
    // 
    // **Comments**
    // - This was orignally many UE's. Consolidated to avoid missed or repeat
    //   API calls and data slices.
    //
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //

    //>>>>>>>>>> Hook 8 - Giphy Search Url <<<<<<<<<<//
    const [yurl, setYurl] = useState({ status: 0, data: {} });
    useEffect(() => {
        const setupGiphySearch = () => {
            if (!gifsearch) {
                return null;
            } else {
                switch (gifsearch.status) {
                    case 0:
                        break;
                    case 1:
                        // Get Search Terms from DB
                        getSearch(gifsearch.data.main, firebase)
                            .then((response) => {
                                const array1 = response.search ? response.search : [];
                                const array2 = response.sub[gifsearch.data.sub] ? response.sub[gifsearch.data.sub] : [];
                                const array3 = response.detail[gifsearch.data.detail] ? response.detail[gifsearch.data.detail] : [];
                                let arrayFinal = array1.concat(array2, array3);
                                if (arrayFinal.length === 0) {
                                    arrayFinal = defaultSearchArray;
                                };
                                setYurl({
                                    status: 1,
                                    data: {
                                        api: "giphy-gifs",
                                        search: arrayFinal[Math.floor(Math.random() * arrayFinal.length)],
                                        qty: 10
                                    }
                                });
                            })
                            .catch((error) => {
                                console.log("Error", error);
                                setYurl({
                                    status: 1,
                                    data: {
                                        api: "giphy-gifs",
                                        search: defaultSearchArray[Math.floor(Math.random() * defaultSearchArray.length)],
                                        qty: 10
                                    }
                                });
                            });
                        break;
                    default:
                        setYurl({
                            status: 1,
                            data: {
                                api: "giphy-gifs",
                                search: defaultSearchArray[Math.floor(Math.random() * defaultSearchArray.length)],
                                qty: 10
                            }
                        });
                        break;
                };
                return null;
            };
        };
        setupGiphySearch();
    }, [gifsearch]);

    //>>>>>>>>>> Hook 9 - Alpha Vantage API Call <<<<<<<<<<//
    const [alphadata, setAlphaData] = useState({ status: 0, data: {} });
    useEffect(() => {
        const fetchAlphaData = async () => {
            if (!aurl) {
                return null;
            } else {
                switch (aurl.status) {
                    case 0:
                        break;
                    case 1:
                        // Check request
                        const urlCheck = aurl.data.ticker + aurl.data.db + aurl.data.size;
                        if (aUrlRef.current === urlCheck) {
                            aUrlRef.current = urlCheck;
                            setAlphaData(prevState => ({ status: 110, data: prevState.data }));
                        } else {
                            // Set busy signal
                            setAlphaData({ status: 400, data: {} });
                            await firebase.doCallAnyNew(aurl.data)
                                .then(result => {
                                    // Set Hook
                                    const aKeys = Object.keys(result.data.data);
                                    const keyString = aKeys[1];
                                    const priceSet = result.data.data[keyString];
                                    setAlphaData({ status: 1, data: priceSet });
                                })
                                .catch(error => {
                                    // Error
                                    console.log("Error", error);
                                    setAlphaData({ status: 900, data: {} });  
                                });      
                        };                 
                        break;
                    default:
                        // Error
                        console.log("Error", aurl);
                        setAlphaData({ status: 900, data: {} }); 
                        break;
                };
                return null;
            };       
        };
        fetchAlphaData();
    }, [aurl]);
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //
    //
    // **Objective**
    // - Alpha Data API call using Google Cloud Function. Dumps previous data.
    // 
    // **Status Codes - alphadata**
    // 0: startup
    // 1: clean response from API
    // 110: no need to recall API, send back same data
    // 400: awaiting data
    // 900: error default data set
    //
    // **Comments**
    // - 
    //
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //

    //>>>>>>>>>> Hook 10 - Google Search API Call <<<<<<<<<<//
    const [googledata, setGoogleData] = useState({ status: 0, data: {} });
    useEffect(() => {
        const fetchGoogleData = async () => {
            if (!gurl) {
                return null;
            } else {
                switch (gurl.status) {
                    case 0:
                        break;
                    case 1:     
                        // Set busy signal
                        setGoogleData({ status: 400, data: {} });
                        await firebase.doCallAnyNew(gurl.data)
                            .then(result => {
                                // Set Hook
                                setGoogleData({ status: 1, data: result.data.data.items });
                            })
                            .catch(error => {
                                // Error
                                console.log("Error", error);
                                setGoogleData({ status: 900, data: {} });   
                            });
                        break;
                    default:
                        // Error
                        console.log("Error", gurl);
                        setGoogleData({ status: 900, data: {} });
                        break;
                };
                return null;
            };   
        };
        fetchGoogleData();
    }, [gurl]);

    //>>>>>>>>>> Hook 11 - Giphy Search API Call <<<<<<<<<<//
    const [mygiphydata, setMyGiphyData] = useState({ status: 0, data: {} });
    useEffect(() => {
        const fetchMyGiphyData = async () => {
            if (!yurl) {
                return null;
            } else {
                switch (yurl.status) {
                    case 0:
                        // Start-up
                        break;
                    case 1:
                        // Set busy signal
                        setMyGiphyData({ status: 400, data: {} });
                        await firebase.doCallAnyNew(yurl.data)
                            .then(result => {
                                // Set Hook
                                setMyGiphyData({ status: 1, data: result.data.data.data });
                            })
                            .catch(error => {
                                // Error
                                console.log("Error", error);
                                setMyGiphyData({ status: 900, data: {} });
                            });
                        break;
                    default:
                        // Error
                        console.log("Error", yurl);
                        setMyGiphyData({ status: 900, data: {} });
                        break;
                };
                return null;
            };
        };
        fetchMyGiphyData();
    }, [yurl]);

    //>>>>>>>>>> Hook 12 - InfoSheet Quote API Call <<<<<<<<<<//
    const [quotedata, setQuoteData] = useState({ status: 0, data: false });
    useEffect(() => {
        const fetchAlphaQuote = async () => {
            if (!qurl) {
                return null;
            } else {
                switch (qurl.status) {
                    case 0:
                        // Start-up
                        break;
                    case 1:
                        // Set busy signal
                        setQuoteData({ status: 400, data: {} });
                        await firebase.doCallAnyNew(qurl.data)
                            .then(result => {
                                // Set Hook
                                setQuoteData({ status: 1, data: result.data.data["Global Quote"] });
                            })
                            .catch(error => {
                                // Error
                                console.log("Error", error);
                                setQuoteData({ status: 900, data: false });
                            });
                        break;
                    default:
                        // Error
                        console.log("Error", gurl);
                        setQuoteData({ status: 900, data: false });
                        break;
                };
                return null;
            }; 
        };
        fetchAlphaQuote();
    }, [qurl]);

    //>>>>>>>>>> Hook 13:14 - User Gif Search & Data <<<<<<<<<<//
    const [zurl, setZurl] = useState({ status: 0, data: {} });
    const [usergiphydata, setUserGiphyData] = useState({ status: 0, data: [] });
    useEffect(() => {
        const fetchUserGiphyData = async () => {
            if (!zurl) {
                return null;
            } else {
                switch (zurl.status) {
                    case 0:
                        // Start-up
                        break;
                    case 1:
                        // Set busy signal
                        setUserGiphyData({ status: 400, data: [] });
                        await firebase.doCallAnyNew(zurl.data)
                            .then(result => {
                                // Set Hook
                                setUserGiphyData({ status: 1, data: result.data.data.data });
                            })
                            .catch(error => {
                                // Error
                                console.log("Error", error);
                                setUserGiphyData({ status: 900, data: [] });
                            });
                        break;
                    default:
                        // Error
                        console.log("Error", zurl);
                        setUserGiphyData({ status: 900, data: [] });
                        break;
                };
                return null;
            };
        };
        fetchUserGiphyData();
    }, [zurl]);

    //>>>>>>>>>> Hook 15:16 - User Sticker Search & Data <<<<<<<<<<//
    const [surl, setSurl] = useState({ status: 0, data: {} }); 
    const [stickerdata, setStickerData] = useState({ status: 0, data: [] });
    useEffect(() => {
        const fetchStickerData = async () => {
            if (!surl) {
                return null;
            } else {
                switch (surl.status) {
                    case 0:
                        // Start-up
                        break;
                    case 1:
                        // Set busy signal
                        setStickerData({ status: 400, data: [] });
                        await firebase.doCallAnyNew(surl.data)
                            .then(result => {
                                // Set Hook
                                setStickerData({ status: 1, data: result.data.data.data });
                            })
                            .catch(error => {
                                // Error
                                console.log("Error", error);
                                setStickerData({ status: 900, data: [] });
                            });
                        break;
                    default:
                        // Error
                        console.log("Error", surl);
                        setStickerData({ status: 900, data: [] });
                        break;
                };
                return null;
            };
        };
        fetchStickerData(); // execute
    }, [surl]);

    //>>>>>>>>>> Reducer 1 - Chart Settings <<<<<<<<<<//
    const [settings, dispatchSettings] = useReducer(settingsReducer, initSettingsReducer);

    //>>>>>>>>>> Reducer 2 - Display Settings <<<<<<<<<<//
    const [display, dispatchDisplay] = useReducer(displayReducer, initDisplayReducer);

    //>>>>>>>>>> 15 - Cloud Function Testing <<<<<<<<<<//
    //const [testfireoutput, setTestFireOutput] = useState({ status: 0, data: {} });
    //useEffect(() => {
    //    const fetchTestData = async () => {
    //        if (!gUrlDefault) {
    //            return;
    //        } else {
    //            await firebase.doCallAnyNew(gUrlDefault)
    //                .then(result => {                      
    //                    setTestFireOutput(result.data);
    //                })
    //                .catch(error => {
    //                    setTestFireOutput({ status: 900, data: {}, error: error })
    //                });                
    //        };
    //    };
    //    fetchTestData();
    //}, []);
    
    //>>>>> DEBUG <<<<<// 
    //console.log(aUrlRef.current);
    //console.log(tickerRef.current);
    //console.log("Chart - input", input);
    //console.log("Chart - aurl", aurl);
    //console.log("Chart - gurl", gurl);
    //console.log("Chart - gifsearch", gifsearch);
    //console.log("Chart - chartmeta", chartmeta);   
    //console.log("Chart - qurl", qurl);
    //console.log("Chart - alphadata", alphadata);
    //console.log("Chart - googledata", googledata);
    //console.log("Chart - quotedata", quotedata);
    //console.log("Chart - mygiphydata", mygiphydata);
    //console.log("Chart - usergiphysearch", usergiphysearch)
    //console.log("Chart - zurl", zurl);
    //console.log("Chart - usergiphydata", usergiphydata);
    //console.log("Chart - settings (reducer)", settings);
    //console.log("Chart - display (reducer)", display);  
    //console.log("Chart - testing", testfireoutput);

    //>>>>>>>>>> JSX Return <<<<<<<<<<//
    return (

        <div
            id="Chart-container"
            ref={sizeRef}
        >            
            <div
                id="Chart-LeftPanel"
                className="Chart-sidePanel"
                hidden={display.view.left ? false : true}
            >
                <LeftPanel
                    firebase={firebase}
                    onDisplay={(e) => { dispatchDisplay(e) }}
                    returnInput={(e) => setInput(e)}                    
                    gData={googledata}
                    yData={mygiphydata}                    
                />
            </div>
            <div
                id="Chart-ChartOutput"
                
            >
                <ChartOutput
                    onDisplay={(e) => { dispatchDisplay(e) }}
                    aData={alphadata}
                    chartMeta={chartmeta}
                    chartSettings={settings}                    
                    cGifs={display.gifs}                    
                    qData={quotedata}
                    chartAspect={chartAspect.current}
                />
            </div>
            <div
                id="Chart-RightPanel"
                className="Chart-sidePanel"
                hidden={display.view.right ? false : true}
            >
                <RightPanel  
                    onSetting={(e) => { dispatchSettings(e) }}
                    onDisplay={(e) => { dispatchDisplay(e) }}
                    searchGifs={(e) => { setZurl(e) }} 
                    searchStickers={(e) => { setSurl(e) }}                     
                    yData={usergiphydata}                    
                    sData={stickerdata}  
                    chartSettings={settings}                    
                />
            </div>
        </div>        
        );
};

//>>>>>>>>>> EXPORTED COMPONENT WITH CONTEXT <<<<<<<<<<//
const ChartPage = ({ authUser, firebase }) => {

    return (
        <div id="Chart-context" >
            <Chart
                authUser={authUser}
                firebase={firebase}               
            />
        </div>
    );
};

//>>>>>>>>>> AUTHORIZATION STATE <<<<<<<<<<//
const condition = authUser => !!authUser;

//>>>>>>>>>> RECOMPOSE EXPORT <<<<<<<<<<//
export default compose(
    withAuthentication,
    withEmailVerification,
    withAuthorization(condition),
)(ChartPage);



