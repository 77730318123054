// Title: index.js (src/components/ChartNew/ChartOutput/CustomTimeTick)

//>>>>> PACKAGES <<<<<//
import React, { PureComponent } from "react";

//>>>>> COMPONENT - CustomizedTimeTick <<<<<//
class CustomTimeTick extends PureComponent {
    
    render() {

        // deconstruct props                        
        let { x, y, myTicks, payload } = this.props; 

        // default tick is blank
        let displayText = "";

        // check if it"s in the desired set of ticks
        //console.log(myTicks.indexOf(payload.value));
        if (myTicks.indexOf(payload.value) !== -1) {
            displayText = payload.value.slice(5);
        };
  
        //>>>>> Debug <<<<<//
        //console.log(x, y, myTicks, payload);

        //>>>>> Return <<<<<//
        return (
            <g                
                transform={`translate(${x},${y})`}
                // need above from recharts example
            >
                <text
                    x={0}
                    y={0}
                    dy={16}
                    fontSize={12}
                    //fontWeight="bold"
                    fontFamily="'Righteous', Courier, monospace"
                    textAnchor="end"
                    fill="#063554"
                    transform="rotate(-40)"
                >{displayText}</text>
            </g>
        );
        
    }
}

export default CustomTimeTick;

