// Title: index.js (src/components/Account/PasswordChange)

//>>>>> NPM PACKAGES <<<<<//
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

//>>>> FORMIK <<<<<//
import MyInput from "../../Formik/MyInput";

//>>>>> SCSS STYLES <<<<<//
import "./styles.scss";

//>>>>> BASE COMPONENT <<<<<//
const PasswordChange = ({ firebase }) => {

    //>>>>> Password Regex <<<<<//
    const regexSet = { one: /(?=.*[0-9])/, two: /(?=.*[!@#$%^&*])/ };

    //>>>>>  <<<<<//
    const [reauth, setReAuth] = useState(false);
    const [formvalues, setFormValues] = useState(
        {
            status: 0,
            data: {
                password_new: "",
                password_conf: ""
            }
        });
    useEffect(() => {
        const updatePassword = async () => {
            if (formvalues.status === 0) {
                return;
            } else if (formvalues.status === 1) {   
                firebase.doPasswordUpdate(formvalues.data.password_new)
                    .catch(error => {
                        setReAuth(true);
                        console.error(error);
                    });                  
            } else {               
                console.error(formvalues);
            };
        };
        updatePassword();
    }, [formvalues, firebase]);

    //>>>>> DEBUG <<<<<//

    //>>>>> JSX RETURN <<<<<//
    return (
        <div id="Passwordchange-container">
            <Formik
                initialValues={{
                    password_new: "",
                    password_conf: ""
                }}
                validationSchema={Yup.object({
                    password_new: Yup.string()
                        .required("Required")
                        .matches(regexSet.one, "at least one number")
                        .matches(regexSet.two, "at least one special character")
                        .min(8, "min 8 characters")
                        .max(32, "max 32 characters"),
                    password_conf: Yup.string()
                        .oneOf([Yup.ref("password_new")], "password mismatch")
                        .required("Required")
                })}
                onSubmit={(values, { resetForm }) => {  
                    setFormValues({ status: 1, data: { ...values } });
                    resetForm();
                }}                
            >
                {({isSubmitting}) => (
                    <Form className="Formik-Form" >
                        <div className="Formik-formRow" >
                            <MyInput
                                title="New Password"
                                className="Formik-MyInput"
                                label=""
                                name="password_new"
                                type="password"
                                placeholder="new password"
                            />
                        </div>
                        <div className="Formik-formRow" >
                            <MyInput
                                title="Confirm Password"
                                className="Formik-MyInput"
                                label=""
                                name="password_conf"
                                type="password"
                                placeholder="confirm password"
                            />
                        </div>
                        <div className="Formik-formRow" >
                            <button
                                disabled={false}
                                className="Base-button"
                                type="submit"
                            ><span>{ isSubmitting ? "\uD83D\uDED1" : "GO!" }</span></button>
                        </div>
                            <div>
                                <p>{formvalues.status === 1 && !reauth ? "Password Changed" : ""}</p>
                                <p>{reauth ? "Please Login Again to Change Password" : ""}</p>
                            </div>
                    </Form>
                    )}                
            </Formik>
        </div>
        );
};

//>>>>> EXPORT <<<<<//
export default PasswordChange;
















