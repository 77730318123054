// index.js (src/components/Landing/SiteMap)

//>>>>> PACKAGES <<<<<//
import React from "react";
import { navigate } from "hookrouter";

//>>>>> SASS STYLES <<<<<//
import "./styles.scss";

function SiteMap({ authUser }) {    
    
    return (
        <div id="SiteMap-container" >
            <div     
                id="SiteMap-header"
                className="Base-flexedCol-block"
            >
                <h3>GO TO</h3>
            </div>
            <div className="Base-flexedCol-block" >
                {authUser ? (
                <div className="SiteMap-links" >
                    <div
                        className="SiteMap-linkRow"
                        onClick={() => navigate("/chart")}
                    >
                        <span
                            title="Go to Chart"
                            className="SiteMap-emoji"

                        >{"\uD83D\uDCC8"}</span>
                        <span className="SiteMap-emoLab" >{" Charts"}</span>
                    </div>
                    <div
                        className="SiteMap-linkRow"
                        //onClick={() => navigate("/news")}
                    >
                        <span
                            title="Go to News (coming soon)"
                            className="SiteMap-emoji"

                        >{"\uD83D\uDCF0"}</span>
                        <span className="SiteMap-emoLab" >{" News"}</span>
                    </div>
                    <div
                        className="SiteMap-linkRow"
                        onClick={() => navigate("/account")}
                    >
                        <span
                            title="Go to Account"
                            className="SiteMap-emoji"

                        >{"\uD83D\uDCBC"}</span>
                        <span className="SiteMap-emoLab" >{" Account"}</span>
                    </div>
                </div>
                ) : (null)}
            </div>
        </div>
        );

};

export default SiteMap;
