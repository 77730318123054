// withEmailVerification.js (src/components/Session)

//>>>>> PACKAGES <<<<<//
import React from 'react';

//>>>>> SESSION <<<<<//
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

//>>>>> SCSS STYLES <<<<<//
import "./styles.scss";

//>>>>> VERIFICATION CHECK <<<<<//
const needsEmailVerification = authUser =>
    authUser &&
    !authUser.emailVerified &&
    authUser.providerData
        .map(provider => provider.providerId)
        .includes('password');

//>>>>> WRAPPER FUNCTION <<<<<//
const withEmailVerification = Component => {

    //>>>>> Wrapped Class Component <<<<<//
    class WithEmailVerification extends React.Component {
        constructor(props) {
            super(props);
            this.state = { isSent: false };
        }

        //>>>>> Verify Email <<<<<//
        onSendEmailVerification = () => {         
            this.props.firebase
                .doSendEmailVerification()
                .then(() => this.setState({ isSent: true }))
                .catch((error) => console.log(error));           
        };

        //>>>>> Render <<<<<//
        render() {

            return (
                <AuthUserContext.Consumer>
                    {authUser => {
                        return (
                            needsEmailVerification(authUser) ? (
                                <div
                                    id="Verify-container"
                                    className="Base-block-flexCol"                                    
                                >
                                    <div className="Base-flexedCol-flexCol" >
                                        {this.state.isSent ? (                                        
                                        <p className="Verify-message" >
                                            E-Mail confirmation sent: Check you E-Mails (Spam
                                            folder included) for a confirmation E-Mail.
                                            Refresh this page once you confirmed your E-Mail.
                                        </p>
                                        ) : (
                                        <p className="Verify-message" >
                                            Verify your E-Mail: Check you E-Mails (Spam folder
                                            included) for a confirmation E-Mail.
                                        </p>                                    
                                        )}
                                    </div>
                                    <div id="Verify-reset" >
                                        <div id="Verify-resetMessage">
                                            <p>
                                                DIDN'T ARRIVE?
                                            </p>
                                        </div>
                                        <div id="Verify-resetButton">
                                            <button
                                                type="button"
                                                className="Base-button"
                                                onClick={this.onSendEmailVerification}
                                                disabled={this.state.isSent}
                                            >RE-SEND</button>
                                        </div>
                                    </div>
                            </div>
                            ) : (
                            <Component {...this.props} />
                            )
                        )
                    }                        
                    }
                </AuthUserContext.Consumer>
            );
        }
    }
    return withFirebase(WithEmailVerification);
};
export default withEmailVerification;