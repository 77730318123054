// index.js (src/components/ChartNew/LeftPanel/ChartInput)

//>>>>> PACKAGES <<<<<//
import React, { useState, useEffect} from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

//>>>>> DATES <<<<<//
import * as DATES from "../../../../data/dateSetup";

//>>>>> FORMIK STANDARD <<<<<//
import MyInput from "../../../Formik/MyInput";

//>>>>> SASS STYLES <<<<<//
import "./styles.scss";

//>>>>> FUNCTION - Date Jump Calculator <<<<<//
const findDateInterval = (enddate, interval) => {    
    const endFull = new Date(enddate); 
    const endMs = endFull.getTime();    
    const startMs = endMs - (86400000 * interval);
    const startFull = new Date(startMs); 
    let newDay = (startFull.getDate()).toString();
    let newMonth = (startFull.getMonth() + 1).toString();
    if (newDay.length === 1) { newDay = "0" + newDay };
    if (newMonth.length === 1) { newMonth = "0" + newMonth };
    let newYear = startFull.getFullYear();
    return (newYear + "-" + newMonth + "-" + newDay);    

    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //
    //
    // **Objective**
    // Given the end date and the desired interval, return the new start date in
    // "YYYY-MM-DD" format, as a string
    // 
    // **Comments**
    // - 
    // -
    //
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //

};

//>>>>> COMPONENT FUNTION <<<<<//  
const ChartInput = ({ firebase, returnInput }) => {    

    //>>>>> 1 - Ticker Search API <<<<<//
    const [livesearch, setLiveSearch] = useState({ status: 0, data: {}});
    const [searchresult, setSearchResult] = useState(false);  
    useEffect(() => {

        const updateLiveSearch = async () => {             
            if (!livesearch) {
                return null;
            } else {
                switch (livesearch.status) {
                    case 0:
                        setSearchResult(false);
                        break;
                    case 1:
                        const liveSearch = {
                            api: 'alpha-search',
                            search: livesearch.data                            
                        };
                        const cloudResult = await firebase.doCallAnyNew(liveSearch)
                            .then(result => {
                                setSearchResult(result.data.data.bestMatches);
                            })
                            .catch(error => {
                                console.log("Error", error);
                                setSearchResult(false);
                            });
                        return cloudResult;
                    default:
                        setSearchResult(false);
                        break;
                };                
            };
        };
        updateLiveSearch();        
    }, [livesearch]);  
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //
    //
    // **Objective**
    // Parse the return from alpha or trigger live search de-render if theres nothing
    //
    // **Input:Output**
    //
    // 
    // **Comments**
    // - 
    //
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //

    //>>>>> 2 - User Selected Interval Type <<<<<//
    const [interval, setInterval] = useState(0);    

    //>>>>> 3 - Sumitting State <<<<<//
    const [submitted, setSubmitted] = useState(false);
    
    //>>>>> JSX Return <<<<<//
    return (
        <div id="ChartInput-container" >
            <div
                id="ChartInput-header"                
                className="Base-flexedCol-block"
            >
                <h3>Charts</h3>
            </div>
            <div
                id="ChartInput-Formik"
                className="Base-flexedCol-block"
            >                    
                <Formik
                    initialValues={{
                        search: "",
                        begin: DATES.startDate,
                        end: DATES.todaysDate,
                        clockStart: "09:30",
                        clockEnd: "16:00",
                    }}
                    validationSchema={Yup.object({
                        search: Yup.string()
                            .min(1)
                            .max(8)
                            .required("Required"),
                        begin: Yup.date()
                            .min("2000-01-01")
                            .max(DATES.todaysDate)
                            .required("Required"),
                        end: Yup.date()
                            .min("2000-01-01")
                            .max(DATES.todaysDate)
                            .required("Required"),
                        clockStart: Yup.string()
                            .min(5)
                            .max(5)
                            .required("Required"),
                        clockEnd: Yup.string()
                            .min(5)
                            .max(5)
                            .required("Required"),
                    })}
                    onSubmit={(values) => {
                        returnInput({
                            status: 1,
                            data: {
                                search: values.search.toUpperCase(),
                                dateStart: values.begin,
                                dateEnd: values.end,
                                clockStart: " " + values.clockStart + ":00",
                                clockEnd: " " + values.clockEnd + ":00",
                                interval: interval,
                            }
                        });
                        setSubmitted(true);
                    }}                    
                >        
                    {({
                        values,                        
                        setFieldValue,
                        handleChange
                    }) => (
                        <Form className="Formik-Form">    
                            <div className="Formik-formRow" >
                                <MyInput         
                                    title="Search Tickers"
                                    className="Formik-MyInput"
                                    label=""
                                    name="search"
                                    type="search"     
                                    placeholder="enter ticker"
                                    onKeyDown={() => setSubmitted(false)}
                                    onKeyUp={() => {
                                        values.search.length >= 1 ?
                                            setLiveSearch({ status: 1, data: values.search }) :
                                            setLiveSearch({ status: 0, data: {} });
                                    }}    
                                />
                                <button
                                    className="Base-button"
                                    title="Go"
                                    type="submit"                                            
                                    ><span role="img" >{"\uD83D\uDC4D"}</span>
                                </button>
                            </div>
                            <div className="Formik-formRow" >
                                <MyInput
                                    title="Start Date"
                                    className="Formik-MyInput"
                                    label=""
                                    name="begin"
                                    type="date"                                            
                                />
                            </div>
                            <div
                                id="ChartInput-jump"
                                className="Base-flexedCol-flexRow"
                            >
                                <span
                                    title="Jump to Date"
                                    role="img"                                       
                                >{"\u21D1"}</span>
                                <button
                                    title="Jump 1 Day"
                                    type="button"                                                                             
                                    onClick={() => {
                                        let newDate = findDateInterval(values.end, 1);
                                        setFieldValue("begin", newDate);
                                    }}
                                >1D</button>
                                <button
                                    title="Jump 1 Week"
                                    type="button"                                                                            
                                    onClick={() => {
                                        let newDate = findDateInterval(values.end, 7);
                                        setFieldValue("begin", newDate);
                                    }}
                                >1W</button>
                                <button
                                    title="Jump 4 Weeks"
                                    type="button"                                                                               
                                    onClick={() => {
                                        let newDate = findDateInterval(values.end, 28);
                                        setFieldValue("begin", newDate);
                                    }}
                                >1M</button>
                                <button
                                    title="Jump 1 Quarter"
                                    type="button"                                                                              
                                    onClick={() => {
                                        let newDate = findDateInterval(values.end, 90);
                                        setFieldValue("begin", newDate);
                                    }}
                                >1Q</button>
                                <button
                                    title="1 Year"
                                    type="button"                                                                               
                                    onClick={() => {
                                        let newDate = findDateInterval(values.end, 365);                                            
                                        setFieldValue("begin", newDate);
                                    }}
                                >1Y</button>
                                <span
                                    title="Jump to Date"
                                    role="img"                                       
                                >{"\u21D1"}</span>
                            </div>
                            <div className="Formik-formRow" >
                                <MyInput
                                    title="End Date"
                                    className="Formik-MyInput"
                                    label=""
                                    name="end"
                                    type="date"                                            
                                />
                            </div> 
                            <div className="ChartInput-int" >
                                <span>FREQ</span>
                                <button
                                    title="Auto"
                                    type="button"  
                                    style={interval === 0 ?
                                        { backgroundColor: "#f7f8ff" } :
                                        { backgroundColor: "#8df2d0" }}
                                    onClick={() => setInterval(0)}
                                >auto</button>
                                <button
                                    title="1 Day"
                                    type="button"  
                                    style={interval === 1 ?
                                        { backgroundColor: "#f7f8ff" } :
                                        { backgroundColor: "#8df2d0" }}
                                    onClick={() => setInterval(1)}
                                >1D</button>
                                <button
                                    title="1 Week"
                                    type="button"  
                                    style={interval === 2 ?
                                        { backgroundColor: "#f7f8ff" } :
                                        { backgroundColor: "#8df2d0" }}
                                    onClick={() => setInterval(2)}
                                >1W</button>
                                <button
                                    title="1 Month"
                                    type="button"  
                                    style={interval === 3 ?
                                        { backgroundColor: "#f7f8ff" } :
                                        { backgroundColor: "#8df2d0" }}
                                    onClick={() => setInterval(3)}
                                >1M</button>
                            </div>
                            <div className="ChartInput-int" >                                    
                                <button
                                    title="1 minute"
                                    type="button"  
                                    style={interval === 4 ?
                                        { backgroundColor: "#f7f8ff" } :
                                        { backgroundColor: "#8df2d0" }}
                                    onClick={() => setInterval(4)}
                                >1m</button>
                                <button
                                    title="5 minutes"
                                    type="button"  
                                    style={interval === 5 ?
                                        { backgroundColor: "#f7f8ff" } :
                                        { backgroundColor: "#8df2d0" }}
                                    onClick={() => setInterval(5)}
                                >5m</button>
                                <button
                                    title="15 minutes"
                                    type="button"  
                                    style={interval === 6 ?
                                        { backgroundColor: "#f7f8ff" } :
                                        { backgroundColor: "#8df2d0" }}    
                                    onClick={() => setInterval(6)}
                                >15m</button>
                                <button
                                    title="30 minutes"
                                    type="button"  
                                    style={interval === 7 ?
                                        { backgroundColor: "#f7f8ff" } :
                                        { backgroundColor: "#8df2d0" }}
                                    onClick={() => setInterval(7)}
                                >30m</button>  
                                <button
                                    title="1 hour"
                                    type="button"  
                                    style={interval === 8 ?
                                        { backgroundColor: "#f7f8ff" } :
                                        { backgroundColor: "#8df2d0" }}
                                    onClick={() => setInterval(8)}
                                >60m</button>    
                            </div> 
                                {interval >= 4 && interval <= 8 ? (
                                    <div>
                                        <div>
                                            <MyInput
                                                title="Start Time"
                                                className=""
                                                label=""
                                                name="clockStart"
                                                type="time"
                                            />
                                        </div>
                                        <div>
                                            <MyInput
                                                title="End Time"
                                                className=""
                                                label=""
                                                name="clockEnd"
                                                type="time"
                                            />
                                        </div>
                                    </div>
                            ) : (null)}  
                            {searchresult && !submitted ? (
                            <div id="ChartInput-live" >
                                {searchresult.map(item => (
                                    <div
                                        title="Select"
                                        className="ChartInput-result"                                                
                                        onClick={() => {
                                            setFieldValue("search", item["1. symbol"]);
                                        }}
                                    >
                                        <span className="ChartInput-result-tick" >{item["1. symbol"]}</span>
                                        <span className="ChartInput-result-comp" >{item["2. name"]}</span>
                                    </div>
                                ))}
                            </div>
                            ) : (null)}                                   
                        </Form>
                    )}
                </Formik>
            </div>        
        </div>
    );    
}

//>>>>> EXPORT <<<<<//
export default (ChartInput);

