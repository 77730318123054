// Title: index.js (src/functions)

//>>>>> IMPORT CONSTANTS <<<<<//
import {
    defaultTicker,
    gifSearchDefault
} from "../constants";

//>>>>> DATABASE QUERY - Ticker <<<<<//
export const getTicker = async (tick, fire, req) => {
    let output;
    if (req) {
        // for price set and chart
        output = fire.ticker(tick)
            .once("value")
            .then(snapshot => {
                let tickSnap = snapshot.val();
                if (!tickSnap) {
                    tickSnap = {
                        ...defaultTicker,
                        ticker: tick
                    }
                };
                return {
                    fromDb: true,
                    ...tickSnap,
                    database: req.database,
                    setSize: req.setSize,
                    subSet: req.subSet
                };
            })
            .catch((error) => {
                console.log("Request Error", error);
                return {
                    ...defaultTicker,
                    ticker: tick,
                    database: "TIME_SERIES_DAILY_ADJUSTED",
                    setSize: "full",
                    subSet: false,
                };
                // In case of database error, return the default
            });
    } else if (!req) {
        // for quote only
        output = fire(tick)
            .once("value")
            .then(snapshot => {
                let tickSnap = snapshot.val();
                if (!tickSnap) {
                    tickSnap = {
                        ...defaultTicker,
                        ticker: tick
                    }
                };
                return {
                    fromDb: true,
                    ...tickSnap,
                };
            })
            .catch((error) => {
                console.log("Request Error", error);
                return {
                    ...defaultTicker,
                    ticker: tick
                };
                // In case of database error, return the default
            });
    } else {
        console.log("Request Error", tick, fire, req)
        output = {
            ...defaultTicker,
            ticker: tick,
            database: "TIME_SERIES_DAILY_ADJUSTED",
            setSize: "full",
            subSet: false,
        };
        // In case of parameter driven error, return the default
    };
    return output;
};

//>>>>> DATABASE QUERY - Search Terms <<<<<//
export const getSearch = async (cat, fire) => {
    const output = fire.searchone(cat)
        .once("value")
        .then(snapshot => {
            let searchResult;
            let searchSnap = snapshot.val();
            if (!searchSnap) {
                searchResult = gifSearchDefault[Math.floor(Math.random() * gifSearchDefault.length)];
            } else {
                searchResult = searchSnap;
            };
            return searchResult;
        })
        .catch((error) => {
            console.log("Request Error", error);
            return gifSearchDefault[Math.floor(Math.random() * gifSearchDefault.length)];
            // In case of database error, return the default
        });    
    return output;
};





