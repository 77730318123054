// index.js (src/components/SignOut)

//>>>>> PACKAGES <<<<<//
import React from "react";
import { compose } from "recompose";

//>>>>> SESSION <<<<<//
import {
    withAuthentication,
    withAuthorization
} from "../Session";

//>>>>> STYLES <<<<<//
import "./styles.scss";

//>>>>> COMPONENT <<<<<//
const SignOutButtonBase = ({ firebase, authUser }) => {

    //>>>>> JSX Return <<<<<//
    return (        
        <div
            title="Sign Out"
            id="SignOut-container"
            onClick={firebase.doSignOut}
        >
            <span
                role="img"
            >{"\uD83D\uDEAA"}</span>
        </div>
    );
}

//>>>>> CONTEXT WRAPPED COMPONENT FUNCTION <<<<<//
const SignOutButton = ({ firebase, authUser }) => (
    <div className="Base-block" >
        <SignOutButtonBase
            firebase={firebase}
            authUser={authUser}
        />
    </div>
);

//>>>>> AUTHORIZATION STATE <<<<<//
const condition = authUser => !!authUser;

//>>>>> RECOMPOSE EXPORT <<<<<//
export default compose(
    withAuthentication,
    withAuthorization(condition),
)(SignOutButton);

// NOTES
// - withAuthorization is redundant here but logically correct for this as
//   a modal component. It should never display to a non signed in user, but
//   at the moment it's never used on a public page
// - firebase sign out method doesn't need authUser but leaving it in to avoid
//   syntax confusion
