// index.js (src)

//>>>>> PACKAGES <<<<<//
import React from "react";
import ReactDOM from "react-dom";
import WebFont from "webfontloader";

//>>>>> LOCAL <<<<<//
import * as serviceWorker from "./serviceWorker";
import App from "./components/App";
import Firebase, { FirebaseContext } from "./components/Firebase";

//>>>>> SASS STYLES <<<<<//
import "./scss/base.scss";

//>>>>> GOOGLE FONTS <<<<<//
WebFont.load({
    google: {
        families: ["Righteous", "Luckiest Guy", "Bangers", "Nunito"]
    }
});

///// COMPONENT RENDER /////
ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();

