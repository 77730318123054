// Title: index.js (src/constants)

// Alpha Vantage API Parameters (note placeholder for 0)
export const reqTypeSet = [
    {},
    {
        database: "TIME_SERIES_DAILY_ADJUSTED",
        setSize: "full",
        subSet: false,
    },
    {
        database: "TIME_SERIES_WEEKLY_ADJUSTED",
        setSize: "full",
        subSet: false,
    },
    {
        database: "TIME_SERIES_MONTHLY_ADJUSTED",
        setSize: "full",
        subSet: false,
    },
    {
        database: "TIME_SERIES_INTRADAY",
        setSize: "full",
        subSet: "1min",
    },
    {
        database: "TIME_SERIES_INTRADAY",
        setSize: "full",
        subSet: "5min",
    },
    {
        database: "TIME_SERIES_INTRADAY",
        setSize: "full",
        subSet: "15min",
    },
    {
        database: "TIME_SERIES_INTRADAY",
        setSize: "full",
        subSet: "30min",
    },
    {
        database: "TIME_SERIES_INTRADAY",
        setSize: "full",
        subSet: "60min",
    }
]; 

// Tickerdata for names not in the db
export const defaultTicker = {
    fromDb: false,
    tickdisplay: "Ponzimemes",
    emoji: false,
    sector1: "ETF",
    sector2: "Index",
    sector3: "Large Cap",
    country: "\uD83C\uDDFA\uD83C\uDDF8",
    summary: false,
    wiki: false,
    people: false,
    nugget: false,
    history: false,
    map: false,
};

export const startupInput = {
    clockStart: ' 09:30:00',
    clockEnd: ' 16:00:00',
    interval: 0,
};

// Preload SPY for speed
// this must match the database structure exactly
export const startupMeta = {
    fromDb: true,
    country: "\uD83C\uDDFA\uD83C\uDDF8",
    emoji: "\uD83D\uDCB2\uD83E\uDD67",
    history: [
        "1993 - Launched on the American Stock Exchange ($ICE)",
        "2000 - Dot-com Bubble peak",
        "2005 - Switched to public float-adjusted capitalization-weighting",
        "2007 - Real Estate Bubble peak",
        "2021 - Still going up"
    ],
    map: "https://goo.gl/maps/CWZBhaMAcTzjzgsYA",
    nugget: [
        "As of 2021 the largest ETF by market cap in the world",
        "You are long this",
        "First true exchange traded fund in the United States"
    ],
    people: [
        {
            link: "https://www.federalreservehistory.org/people/jerome-h-powell",
            display: "Jerome Powell (b. 1953)"
        },
        {
            link: "http://www.gutenberg.org/ebooks/140",
            display: "You"
        }
    ],
    search: ["Jerome Powell"],
    sector1: "ETF",
    sector2: "Index",
    sector3: "S&P 500",
    summary: "The SPDR S&P 500 ETF Trust (SPY) is an exchange traded fund managed by State Street Global Advisors (STT). It tracks the performance of  the S&P500 Index, which represents the US economy depending on which party the president is and what channel you are watching.",
    tickdisplay: "SPDR S&P 500 ETF Trust",
    ticker: "SPY",
    wiki: "S%26P_500_Index",   
};

// GCF request defaults for error catch
export const aUrlDefault = {
        api: "alpha-set",
        ticker: "SPY",
        db: "TIME_SERIES_DAILY_ADJUSTED",
        size: "full",
        sub: ""
};

export const gUrlDefault = {
        api: "google-links",
        search: "SP500 Index",
        qty: 10
};

export const gifSearchDefault = {
    sub: "Regional",
    detail: "Large Cap",
    main: "ETF"
}

export const defaultSearchArray = ["stonks", "gains", "moon", "elon", "portnoy", "rich", "crash", "scam"];

export const qUrlDefault = {
    api: "alpha-quote",
    ticker: "SPY",
};


// Initial Values for Chart useReducers //
export const initSettingsReducer = {
    open: { status: false, color: "blue" },
    close: { status: false, color: "black" },
    high: { status: false, color: "green" },
    low: { status: false, color: "red" },
    symbol: { status: true, color: "candle" }
};
export const initDisplayReducer = {
    view: {
        left: true,
        right: true,
    },
    gifs: [],
};

// Initial Values for Landing useReducers //
export const initLandDisp = { signUp: false, forgotPw: false };

// LandingConsole Tickers //
export const tickerArray = ["SPY", "QQQ", "IWM", "GLD", "USO", "VXX", "FB", "AMZN", "NFLX", "GOOGL",
    "MSFT", "AAPL", "TSLA", "F", "DIS", "GS", "BA", "PENN", "BAC", "WMT",
    "GME", "GDX"];
// works best with even number of tickers

// LandingConsole Empty Tile //
export const emptyTicker = {
    emoji: "",
    open: 0,
    high: 0,
    low: 0,
    price: 0,
    volume: 0,
    date: 0,
    prev: 0,
    change: 0,
    percent: "0",
};