// Title: index.js (src/components/Landing/SignIn)

//>>>>> PACKAGES <<<<<//
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

//>>>>> FORMIK <<<<<//
import MyInput from "../../Formik/MyInput";

//>>>>> SASS STYLES <<<<<//
import "./styles.scss";

//>>>>> COMPONENT <<<<<//
const SignIn = ({ firebase, onDispatch }) => {

    //>>>>> 1 - Form Data <<<<<//
    const [formvalues, setFormValues] = useState({
        status: 0,
        data: {
            email: "",
            password: ""
        }
    });
    useEffect(() => {
        const loginGo = async () => {
            if (formvalues.status === 0) {
                return;
            } else if (formvalues.status === 1) {
                firebase
                    .doSignInWithEmailAndPassword(
                        formvalues.data.email.trim(),
                        formvalues.data.password)
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                // Yup should catch anything before here                
                console.error(formvalues);
            };            
        };
        loginGo();
    }, [formvalues]);

    //>>>>> JSX Return <<<<<//
    return (
        <div id="SignIn-container" >
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .required("Required")
                        .min(6, "Invalid Email/Password")
                        .max(80, "Character Limit Exceeded")
                        .email("Invalid Email"),
                    password: Yup.string()
                        .required("Required")
                        .min(1, " ")
                        .max(32, " "),
                })}
                onSubmit={(values, { resetForm }) => {
                    setFormValues({ status: 1, data: { ...values } });
                    resetForm();
                }} 
            >
                {({ isSubmitting }) => (
                <Form className="Formik-Form" >
                    <div className="Formik-formRow" >
                        <MyInput                                
                            className="Formik-MyInput"
                            label=""
                            name="email"
                            type="email"
                            placeholder="email address"
                        />
                    </div>
                    <div className="Formik-formRow" >
                        <MyInput        
                            className="Formik-MyInput"
                            label=""
                            name="password"
                            type="password"
                            placeholder="password"
                        />
                    </div>
                    <div className="Formik-formRow" >
                        <button    
                            className="Landing-bigButton"
                            type="submit"
                        >
                            <span>{isSubmitting ? "\uD83D\uDED1" : "GO!"}</span>
                        </button>
                    </div>
                    <div className="Formik-formRow" >
                        <p
                            id="SignIn-reset"
                            onClick={() => { onDispatch("forgotPw") }}
                        >FORGOT PW?</p>
                    </div>
                </Form>
                )}   
            </Formik>
        </div>
    );
};

//>>>>> EXPORT <<<<<//
export default SignIn;

