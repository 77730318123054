// index.js (src/components/ChartNew/RightPanel/GifFixed)

//>>>>>>>>>> PACKAGES <<<<<<<<<<//
import React from "react";

//>>>>>>>>>> LOCAL MEDIA IMPORT <<<<<<<<<<//
import gifThumb from "../../../../img/giphy.png";

//>>>>>>>>>> SCSS STYLES <<<<<<<<<<//
import "./styles.scss";

//>>>>>>>>>> COMPONENT FUNCTION <<<<<<<<<<//
function GifFixed({ gifMeta, onDisplay }) {

    //>>>>>>>>>> JSX Return <<<<<<<<<<//
    return (
        
        <div className="GifFixed-container" >

            {/* FRAME WRAP - analog to Card Front or Back in GifFixed */}
            <div
                className="GifFixed-outer"
                style={{ height: Math.round(200 / (gifMeta.width / gifMeta.height)) + "px" }}
                // height fixed here since no flip 
            >
                {/* BORDER */}
                <div className="GifFixed-border" >

                    {/* GIF */}
                    <div
                        title="Gifs by Giphy"
                        className="GifFixed-cellophane"
                    >
                        <iframe
                            title="Giphy"
                            src={gifMeta.url}
                        />
                    </div>

                    {/* FOOTER */}
                    <div className="GifFixed-footer" >
                        <div>
                            <button
                                title="Load"
                                onClick={() => {
                                    onDisplay({
                                        type: "add-gif",
                                        data: {
                                            url: gifMeta.url,
                                            height: gifMeta.height,
                                            width: gifMeta.width,
                                        }
                                    })
                                }}
                            ><span role="img">{"\uD83D\uDCB8"}</span></button>
                        </div>
                        <div>
                            <a
                                title="Visit Giphy"
                                href={gifMeta.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    alt="Giphy"
                                    src={gifThumb}
                                />
                            </a>
                        </div>
                    </div>

                </div>  
                
            </div>

        </div>
            
    );        
};

//>>>>>>>>>> EXPORT <<<<<<<<<<//
export default GifFixed;






