// index.js (src/components/ChartNew/LeftPanel)

//>>>>>>>>>> PACKAGES <<<<<<<<<<//
import React from "react";

//>>>>>>>>>> LOCAL IMPORT <<<<<<<<<<//
import ChartInput from "./ChartInput";
import GifPanel from "./GifPanel";

//>>>>>>>>>> SCSS STYLES <<<<<<<<<<//
import "./styles.scss";

//>>>>>>>>>> COMPONENT FUNCTION <<<<<<<<<<//
function LeftPanel({
    firebase,
    onDisplay,
    returnInput,
    gData,
    yData
}) { 

    //>>>>>>>>>> JSX Return <<<<<<<<<<//
    return (          
        <div id="LeftPanel-container" >
            <div
                id="LeftPanel-ChartInput"
                className="Base-flexedCol-block"
            >  
                <ChartInput                            
                    returnInput={returnInput}
                    firebase={firebase}
                />
            </div> 
            <div
                id="LeftPanel-MyTileBoard"
                className="Base-flexedCol-block"
            >      
                <GifPanel
                    onDisplay={onDisplay}
                    gData={gData}
                    yData={yData}
                />
            </div>
        </div>                  
    );    
};

//>>>>>>>>>> EXPORT <<<<<<<<<<//
export default LeftPanel;

