// Title: index.js (src/components/Admin)

import React from "react";
import { compose } from "recompose";

//>>>>> SESSION <<<<<//
import {
    withAuthentication,
    withAuthorization,
    withEmailVerification
} from "../Session";

//>>>>> BASE COMPONENT <<<<<//
const Admin = ({ firebase, authUser }) => {

    //>>>>> JSX Return <<<<<//
    return (
        <div id="Admin-container">
            <div>
                {authUser.roles.ADMIN ? (
                    <p>ADMIN</p>
                ) : (
                    <p>ADMINS ONLY PISS OFF</p>
                )}
            </div>
        </div>
    );
};
// Admin Status check is redundant but leave it in
// Non-admin user should never be able to get here

//>>>>> EXPORTED COMPONENT WITH CONTEXT <<<<<//
const AdminPage = ({ authUser, firebase }) => {
    return (
        <div className="Base-block" >
            <Admin
                authUser={authUser}
                firebase={firebase}                
            />
        </div>
    );
}

//>>>>> AUTHORIZATION STATE <<<<<//
const condition = authUser => !!authUser;

//>>>>> RECOMPOSE EXPORT <<<<<//
export default compose(
    withAuthentication,
    withEmailVerification,
    withAuthorization(condition),
)(AdminPage);


