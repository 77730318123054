// index.js (src/components/ChartNew/rightPanel/ChartSettings/PopMenu

//>>>>> PACKAGES <<<<<//
import React from "react";
import Popup from "reactjs-popup";

//>>>>> NPM COMPONENT STYLE <<<<<//
import "reactjs-popup/dist/index.css";

//>>>>> SASS STYLES <<<<<//
import "./styles.scss";

const colorPallet = [
    {
        name: "Rekt Red",
        hex: "#e8000d",
    },
    {
        name: "Lambo Red",
        hex: "#ff0000",
    },
    {
        name: "Undergrad Red",
        hex: "#c90016",
    },
    {
        name: "Presidential Orange",
        hex: "#f94d00"
    },
    {
        name: "Just Buy BTC Gold",
        hex: "#ffd700"
    },
    {
        name: "Yolo Green",
        hex: "#009000",
    },
    {
        name: "Casino Green",
        hex: "#195905"
    },
    {
        name: "Mnuchin Green",
        hex: "#a0d6b4",
    },
    {
        name: "Hamptons Blue",
        hex: "#002fa7",
    },
    {
        name: "Grad School Blue",
        hex: "#0f4d92"
    },
    {
        name: "Cheap Pillow Purple",
        hex: "#663399"
    },
    {
        name: "Non-GAAP Black",
        hex: "#000000",
    },
    {
        name: "Benzo Silver",
        hex: "#979aaa"
    },   
];

//>>>>> COMPONENT - GifAdjust <<<<<//
function PopMenu(props) {

    //console.log("PopMenu - props", props);
    const contentStyle = { background: "#81f7a8" };

    //>>>>> RETURN <<<<<//
    return (

        <div>
            
            <Popup
                {...{contentStyle}}
                trigger={
                    <button
                        className="Base-button"
                        style={{minWidth: '2.0em'}}
                    >
                        <span role='img' >{props.emoji}</span>
                    </button>
                }
                position="bottom right"
                offsetX={0}
                offsetY={12}
                arrow={false}
            >
                <div className="PopMenu-container" >
                    <div className="PopMenu-row" >
                        {props.name === "symbol" ? (
                            null
                        ) : (
                            <form
                                onChange={(e) => {
                                    props.onDispatch({
                                        type: "color",
                                        direction: {
                                            name: props.name,
                                            context: e.target.value,
                                        }
                                    });
                                }}
                            >
                                <select>
                                    <option value="" disabled selected>{"\uD83C\uDFA8"}</option>
                                    {colorPallet.map(item => {
                                        return (
                                            <option
                                                value={item.hex}
                                                title={item.name}
                                                style={{
                                                    padding: "2px",
                                                    margin: "2px 0 2px 0",
                                                    backgroundColor: item.hex,
                                                    color: "white"
                                                }}
                                            >{" "}</option>
                                        )
                                    })}
                                </select>
                            </form>
                        )}
                        
                    </div>
                    <div>
                        <button
                            onClick={() => {
                                props.onDispatch({
                                    type: "toggle",
                                    direction: {
                                        name: props.name,
                                        context: ""
                                    }
                                })}}
                        >
                            <span role="img" >{"\uD83D\uDCA1"}</span>
                        </button>
                    </div>
            </div>    
        </Popup>
        </div>
     );
}

export default (PopMenu);
