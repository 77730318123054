// index.js (src/components/ChartNew/LeftPanel/GifPanel/GifFlip)

//>>>>>>>>>> PACKAGES <<<<<<<<<<//
import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";

//>>>>>>>>>> SCSS STYLES <<<<<<<<<<//
import "./styles.scss";

//>>>>>>>>>> COMPONENT <<<<<<<<<<//
function GifFlip({ gifMeta, onDisplay }) {

    //>>>>>>>>>> Flip Condition <<<<<<<<<<//
    const [isflipped, setIsFlipped] = useState(false);  

    //>>>>>>>>>> JSX Return <<<<<<<<<<//
    return (
        
        <div className="GifFlip-container" >

            {/* EXTERNAL COMPONENT */}
            <ReactCardFlip
                isFlipped={isflipped}
                flipDirection="horizontal"
                containerStyle={{ height: Math.round(200 / (gifMeta.yWidth / gifMeta.yHeight)) + "px"}}
            >               

                {/* CARD FRONT (see component syntax) */}                
                <div className="GifFlip-card-outer" >
                    <div className="GifFlip-border" >
                        <div
                            title="Flip Me"
                            className="GifFlip-cellophane"
                            onClick={() => setIsFlipped(!isflipped)}
                        >                            
                            <iframe title="Giphy" className="GifFlip-frame" src={gifMeta.yUrl} />                            
                        </div>
                    </div>
                </div>                

                {/* CARD BACK (see component syntax) */}                
                <div className="GifFlip-card-outer" >  
                    <div className="GifFlip-border" >                            
                        <div className="GifFlip-cardBack-inner" >
                            <div className="GifFlip-link" >
                                <img alt="thumb" src={gifMeta.gThumb}/>
                                <a
                                    title="google"                                                               
                                    href={gifMeta.gLink}
                                    target="_blank"  
                                    rel="noopener noreferrer"
                                >{gifMeta.gTitle}</a>
                            </div>
                            <div className="GifFlip-footer" >
                                <div>
                                    <button
                                        title="Flip Me"
                                        onClick={() => setIsFlipped(!isflipped)}
                                    ><span role="img" >{"\u21A9"}</span></button>
                                </div>
                                <div>
                                    <button                                        
                                        title="Use Gif"
                                        onClick={() => {
                                            onDisplay({
                                                type: "add-gif",
                                                data: {
                                                    url: gifMeta.yUrl,
                                                    height: gifMeta.yHeight,
                                                    width: gifMeta.yWidth,
                                                }
                                            })
                                        }}
                                    ><span role="img" >{"\uD83D\uDCB8"}</span></button>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>   

            </ReactCardFlip>  
            
        </div>

    );
};

//>>>>>>>>>> EXPORT <<<<<<<<<<//
export default (GifFlip);

