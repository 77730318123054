// Title: index.js (src/components/Chart/ChartOutput/InfoSheet) //

//>>>>> NPM PACKAGES <<<<<//
import React from "react";

//>>>>> SCSS STYLES <<<<<//
import "./styles.scss";

//>>>>> COMPONENT FUNCTION - InfoSheet <<<<<//
function InfoSheet({ metaData, qData }) {

    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //
    //
    // **Objective**
    // Static component, props & JSX only.    
    // 
    // **Comments**
    // - 
    //
    // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes // Notes //

    //>>>>> JSX RETURN <<<<<//
    return (
        <div id="InfoSheet-container" >
            {metaData.status === 1 ? 
            (<div id="InfoSheet-headerO" >
                <h2 id="InfoSheet-header" >
                    <span
                        title="Emoji Ticker"
                        role="img"
                        id="InfoSheet-headEmoji"
                        >{metaData.data.emoji ?
                            metaData.data.emoji : "\uD83E\uDD14"}</span>
                    <span
                        title="Company Name"
                        id="InfoSheet-headTitle"
                        >{metaData.data.fromDb ?
                            metaData.data.tickdisplay +
                            " ($" + metaData.data.ticker + ")" :
                            "$" + metaData.data.ticker}</span>
                </h2>
            </div>) : (null)}
            {metaData.status === 1 && qData.status === 1 ?
            (<div id="InfoSheet-body">
                <div id="InfoSheet-quote">
                    <div id="InfoSheet-bubble">
                        <p>{"$ " + qData.data["05. price"]}</p>
                        <p
                            style={qData.data["09. change"] >= 0 ?
                                { backgroundColor: "#50d950" } :
                                { backgroundColor: "#f55656" }}
                        >
                            <span>{"\u0394 "}</span>
                            <span>{qData.data["09. change"]}</span>
                            <span>{qData.data["10. change percent"]}</span>
                        </p>
                        <p>
                            <span>{"\uD83D\uDD0A" +
                                qData.data["06. volume"]}</span>
                        </p>
                    </div>
                </div>
                <div id="InfoSheet-summary">
                    <div>
                        <h3>ABOUT</h3>
                        <p>
                            {metaData.data.summary ?
                                metaData.data.summary : "Coming Soon!"}
                        </p>                            
                    </div>
                    {metaData.data.history ? 
                    (<div>
                        <h3>HISTORY</h3>
                        <ul>
                            {metaData.data.history.map((item) =>
                                <li>{item}</li>)}
                        </ul>
                    </div>) : (null)}
                    {metaData.data.launch || metaData.data.nugget ? 
                    (<div>
                        <h3>NUGGETS</h3>
                        <ul>
                            {metaData.data.launch &&
                                !metaData.data.history ? (
                                <li>{metaData.data.sector1 === "ETF" ?
                                    "Launched in " + metaData.data.launch :
                                    "Founded in " + metaData.data.launch}</li>
                            ) : (null)}
                            {metaData.data.ipo &&
                                !metaData.data.history &&
                                metaData.data.sector1 !== "ETF" ? (
                                <li>{"IPO'd in " + metaData.data.ipo}</li>
                            ) : (null)}
                            {metaData.data.nugget ? (
                                metaData.data.nugget.map((item) =>
                                    <li>{item}</li>
                            )) : (null)}
                        </ul>
                    </div>) : (null)}                        
                </div>
                <div id="InfoSheet-links">
                    {metaData.data.sector1 ? <h3>SECTORS</h3> : null}
                    {metaData.data.sector1 ? <p>{"\uD83E\uDD47 " + metaData.data.sector1}</p> : null}
                    {metaData.data.sector2 ? <p>{"\uD83E\uDD48 " + metaData.data.sector2}</p> : null}
                    {metaData.data.sector3 ? <p>{"\uD83E\uDD49 " + metaData.data.sector3}</p> : null}
                    <h3>MORE</h3>
                    {metaData.data.wiki ? 
                    (<a
                        href={"https://en.wikipedia.org/wiki/" +
                            metaData.data.wiki}
                        target="_blank"
                        rel="noopener noreferrer"
                    >Wikipedia</a>) : (null)}
                    <a
                        href={"https://twitter.com/search?q=%24" +
                            metaData.data.ticker +
                            "&src=external"}
                        target="_blank"
                        rel="noopener noreferrer"
                    >Tweets</a>
                    <a
                        href={"https://www.reddit.com/search/?q=" +
                            metaData.data.ticker +
                            "%20subreddit%3Awallstreetbets"}
                        target="_blank"
                        rel="noopener noreferrer"
                    >WallStreetBets</a>
                    {metaData.data.map ?
                    (<a
                        href={metaData.data.map}
                        target="_blank"
                        rel="noopener noreferrer"
                    >Google Maps</a>) : (null)}
                    {metaData.data.people ?
                    (<h3>MVP'S</h3>) : (null)}
                    {metaData.data.people ? 
                    (metaData.data.people.map(item =>
                    <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >{item.display}</a>
                    )) : (null)}
                </div>
            </div>) : (null)}            
        </div>
    );
    
};

//>>>>> EXPORT <<<<<//
export default InfoSheet


