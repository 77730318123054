// Title: index.js (src/components/ChartNew/ChartOutput/LineTip)

//>>>>> PACKAGES <<<<<//
import React, { PureComponent } from "react";

//>>>>> STYLES <<<<<//
import "./styles.scss";

//>>>>> COMPONENT - MyTooltip <<<<<//
class LineTip extends PureComponent {    

    //>>>>> Render <<<<<//
    render() {       

        let displayStatus = false;
        let priceColor = "#f0fff4";
        let priceSet = [];
        if (this.props.payload && this.props.payload.length === 4) {
            displayStatus = true;
            priceSet =
            [                    
                this.props.payload[0]["payload"]["open"].toFixed(2),
                this.props.payload[0]["payload"]["close"].toFixed(2),
                this.props.payload[0]["payload"]["low"].toFixed(2),
                this.props.payload[0]["payload"]["high"].toFixed(2),
                this.props.payload[0]["payload"]["date"],
            ];
            priceSet[1] >= priceSet[0] ? priceColor = "#f0fff4" : priceColor = "#fff0f0";
        };

        //>>>>> Return <<<<<//
        return (
            <div id="LineTip-container" >
                {displayStatus ? (
                    <div id="LineTip-content"  >
                        <div className="LineTip-row" >
                            <span role="img" >{"\u23EB"}</span>
                            <span>{priceSet[3]}</span>
                        </div>
                        <div
                            className="LineTip-row"
                            style={{backgroundColor: priceColor}}
                        >
                            <span className="LineTip-emoji" role="img" >{"\uD83D\uDCD5"}</span>
                            <span className="LineTip-number" >{priceSet[1]}</span>
                        </div>
                        <div
                            className="LineTip-row"
                            style={{ backgroundColor: priceColor }}
                        >
                            <span role="img" >{"\uD83D\uDCD6"}</span>
                            <span>{priceSet[0]}</span>
                        </div>
                        <div className="LineTip-row" >
                            <span role="img" >{"\u23EC"}</span>
                            <span>{priceSet[2]}</span>
                        </div>
                        <div
                            className="LineTip-row"
                            style={{ marginBottom: "0px"}}
                        >
                            <span>{priceSet[4]}</span>
                        </div>
                    </div>
                ) : (
                        null
                    )}
            </div>
        );
    }
};

export default LineTip;

