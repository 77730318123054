// Title: index.js (src/RightPanel/ChartSettings)

//>>>>> PACKAGES <<<<<//
import React from "react";

//>>>>> Components <<<<<//
import PopMenu from "./PopMenu";

//>>>>> SASS STYLES <<<<<//
import "./styles.scss";

//>>>>> COMPONENT <<<<<//
function ChartSettings(props) {

    //>>>>> DEBUG <<<<<//
    //console.log("ChartSettings - props", props);

    //>>>>> RETURN <<<<<//
    return (
        <div id="ChartSettings-container" >
            <div className="Base-flexedCol-block" >
                <h3>Customize</h3>
            </div>
            <div className="ChartSettings-row" >
                <div className="Base-flexedRow-block" >
                    <PopMenu
                        name="open"
                        emoji={"\uD83D\uDCD6"}
                        onDispatch={props.onDispatch}
                    />
                </div>
                <div className="Base-flexedRow-block" >
                    <PopMenu
                        name="close"
                        emoji={"\uD83D\uDCD5"}
                        onDispatch={props.onDispatch}
                    />
                </div>
            </div>    
            <div className="ChartSettings-row" >
                <div className="Base-flexedRow-block" >
                    <PopMenu
                        name="high"
                        emoji={"\u23EB"}
                        onDispatch={props.onDispatch}
                    />
                </div>
                <div className="Base-flexedRow-block" >
                    <PopMenu
                        name="low"
                        emoji={"\u23EC"}
                        onDispatch={props.onDispatch}
                    />
                </div>
            </div>    
            <div className="ChartSettings-row" >
                <PopMenu
                    name="symbol"
                    emoji={"\uD83D\uDD6F"}
                    onDispatch={props.onDispatch}
                />
            </div>                                   
         </div>  
    );
};

export default ChartSettings;

