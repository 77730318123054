// index.js (src/components/ChartNew/RightPanel/GiphySearch)

//>>>>>>>>>> PACKAGES <<<<<<<<<<//
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

//>>>>>>>>>> FORMIK <<<<<<<<<<//
import MyInput from "../../../Formik/MyInput";

//>>>>>>>>>> SCSS STYLES <<<<<<<<<<//
import "./styles.scss";

//>>>>>>>>>> COMPONENT FUNCTION <<<<<<<<<<//
function GiphySearch({ api, onClick, search }) {

    //>>>>>>>>>> JSX Return <<<<<<<<<<//
    return (  
        <div className="GiphySearch-container" >                       
            <Formik
                initialValues={{
                    search: ""  
                }}
                validationSchema={Yup.object({
                    search: Yup.string()    
                        .min(2)
                        .max(32)
                        .required("Required"),                            
                })}
                onSubmit={(values) => {
                    search(
                        {
                            status: 1,
                            data: {
                                api: api,
                                search: values.search,
                                qty: 5
                            }
                        }
                    )}}              
            >
            <Form className="Formik-Form">                      
                    <div className="Formik-formRow" >    
                        <MyInput
                            className="Formik-MyInput"
                            label=""
                            name="search"
                            type="search"                            
                            placeholder="search"
                        />  
                        <button
                            className="Base-button"
                            title="Find Gifs"                            
                            type="submit"
                            onClick={onClick}
                        ><span role="img" >{"\uD83D\uDC4D"}</span>
                        </button>
                    </div>         
                </Form>
            </Formik>
           
        </div>
    );
}

//>>>>> EXPORT <<<<<//
export default GiphySearch;

