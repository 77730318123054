// index.js (src/components/ChartNew/ChartOutput/BarTip)

//>>>>> PACKAGES <<<<<//
import React, { PureComponent } from "react";

//>>>>> SASS STYLES <<<<<//
import "./styles.scss";

//>>>>> COMPONENT - MyTooltip <<<<<//
class BarTip extends PureComponent {

    //>>>>> Render <<<<<//
    render() {

        let displayStatus = false;
        let volumeSet = [];
        let volumeNum = 0;
        if (this.props.payload && this.props.payload.length === 1) {
            displayStatus = true;
            volumeNum = this.props.payload[0]["payload"]["volume"].toFixed(0);
            volumeSet =
                [
                    volumeNum,
                    this.props.payload[0]["payload"]["date"],
                ];
        };  

        //>>>>> Return <<<<<//
        return (
            <div id="BarTip-container" >
                {displayStatus ? (
                    <div className="BarTip-content">
                        <div
                            className="BarTip-row"
                            style={{margin: "0 0 6px 0"}}
                        >
                            <span role="img" >{"\uD83D\uDD0A"}</span>
                            <span>{volumeSet[0].toLocaleString("en-US")}</span>
                        </div>                        
                        <div className="BarTip-row" >
                            <span>{volumeSet[1]}</span>
                        </div>
                    </div>
                ) : (
                        <div></div>
                    )}
            </div>
        );
    }
};

export default BarTip;
