// Title: index.js (src/components/ChartNew/ChartOutput/Candle)

//>>>>> PACKAGES <<<<<//
import React, { PureComponent } from "react";

//>>>>> COMPONENT <<<<<//
class Candle extends PureComponent {    

    render() {

        //>>>>> Debug <<<<<//
        //console.log(this.props); 
        //console.log(this.props.svgScale);
        
        const vbWidth = 10 * this.props.svgScale.width;
        const vbHeight = 10 * this.props.svgScale.height * this.props.payload.range;

        const myViewBox = "0 0 " + (Math.round(vbWidth)).toString() + " " + (Math.round(vbHeight)).toString();

        const highHeight = 10 * (this.props.svgScale.height * (this.props.payload.high - Math.max(this.props.payload.open, this.props.payload.close)));
        const mainHeight = 10 * this.props.svgScale.height * this.props.payload.delta;
        //const lowHeight = 10 * (this.props.svgScale.height * (Math.min(this.props.payload.open, this.props.payload.close) - this.props.payload.low));       
        
        const midPoint = Math.round(0.5 * vbWidth);      
        
        const mainLine = midPoint + "," + Math.round(highHeight) + " " + midPoint + "," + Math.round(highHeight + mainHeight);
        const highLine = midPoint + ",0 " + midPoint + "," + Math.round(highHeight);
        const lowLine = midPoint + "," + Math.round(highHeight + mainHeight) + " " + midPoint + "," + Math.round(vbHeight);

        const strokeWidth = Math.round(0.8 * vbWidth);
        const thinWidth = Math.round(0.2 * vbWidth);
        

        //>>>>> Debug <<<<<//        

        //>>>>> Return <<<<<//
        return (
            <svg
                x={this.props.cx - (0.5 * this.props.svgScale.width)}
                y={this.props.cy - (this.props.payload.high - this.props.payload.close) * this.props.svgScale.height}
                width={Math.round(this.props.svgScale.width.toString())}
                height={(Math.round(this.props.svgScale.height * this.props.payload.range)).toString()}
                viewBox={myViewBox}
            >                
                <polyline
                    points={mainLine}
                    stroke={this.props.payload.up}
                    strokeWidth={strokeWidth}
                />                
                <polyline
                    points={highLine}
                    stroke={"black"}
                    strokeWidth={thinWidth}
                />  
                <polyline
                    points={lowLine}
                    stroke={"black"}
                    strokeWidth={thinWidth}
                />                
            </svg>
        );
    }
}

export default Candle;

