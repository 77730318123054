// Title: index.js (src/components/Landing/PasswordForget)

//>>>>> PACKAGES <<<<<//
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

//>>>> FORMIK <<<<<//
import MyInput from "../../Formik/MyInput";

//>>>>> SCSS STYLES <<<<<//
import "./styles.scss";

//>>>>> COMPONENT <<<<<//
const PasswordForget = ({ firebase }) => {

    const [formvalues, setFormValues] = useState({ status: 0, data: { email: "" } });
    useEffect(() => {
        const resetPassword = async () => {
            if (formvalues.status === 0) {
                return;
            } else if (formvalues.status === 1) {
                firebase.doPasswordReset(formvalues.data.email)
                    .then(() => {
                        // reset form here if desired
                        // can also flip display
                    }) 
                    .catch(error => console.error(error));
            } else {
                console.error(formvalues);
            };
        };
        resetPassword();

    }, [formvalues]);

    //>>>>> JSX Return <<<<<//
    return (
        <div id="PasswordForget-container" >
            <Formik
                initialValues={{
                    email: "",
                }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .required("Required")
                        .min(8, "Invalid Email")
                        .max(64, "Invalid Email")
                        .email("Invalid Email"),
                })}
                onSubmit={(values) => {
                    setFormValues({ status: 1, data: { ...values }});
                }}
            >
                <Form className="Formik-Form" >
                    <div className="Formik-formRow" >
                        <MyInput
                            title="Email Address"
                            className="Formik-MyInput"
                            label=""
                            name="email"
                            type="text"
                            placeholder="email address"
                        />
                        <button
                            className="Base-button"
                            type="submit"
                        ><span>GO</span></button>
                    </div>
                </Form>
            </Formik>
        </div>
    );
};

//>>>>> EXPORT <<<<<//
export default PasswordForget;



