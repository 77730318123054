// index.js (src/components/ChartNew/ChartOutput/ChartGif).

//>>>>> PACKAGES <<<<<//
import React, { useState, useEffect, useReducer } from "react";
import Draggable from "react-draggable";

//import GifSettings from "./GifSettings";
import GifPopup from "./GifPopup";

//>>>>> SASS STYLES <<<<<//
import "./styles.scss";

//>>>>> INITIAL REDUCER <<<<<//
const initSettings = {
    width: 180,
    clear: 1.0,
    angle: 0,
    radius: 0,
    spinX: 0,
    spinY: 0,
    skewX: 0,
    skewY: 0,
    contrast: 1,
    hue: 0,
    blur: 0,
    gray: 0,
    //shadow: 0, (offsetx, offsety, radius, color)
    //border: "none",    
};

// Other CSS to consider
// border
// aspect ratio (independent x & y dims)
// clipping & masking (very advanced)
// backdrop-filter

//>>>>> REDUCER FUNCTION <<<<<//
function settingsReducer(initState, action) {
    let newState;
    switch (action.name) {
        case "width":
            newState = {
                ...initState,
                [action.name]: action.value
            };
            return newState;
        case "clear":
            newState = {
                ...initState,
                [action.name]: action.value
            };
            return newState;
        case "angle":
            newState = {
                ...initState,
                [action.name]: action.value
            };
            return newState;
        case "radius":
            newState = {
                ...initState,
                [action.name]: action.value
            };
            return newState;
        case "spinX":
            newState = {
                ...initState,
                [action.name]: action.value
            };
            return newState;
        case "spinY":
            newState = {
                ...initState,
                [action.name]: action.value
            };
            return newState;
        case "skewX":
            newState = {
                ...initState,
                [action.name]: action.value
            };
            return newState;
        case "skewY":
            newState = {
                ...initState,
                [action.name]: action.value
            };
            return newState;     
        case "contrast":
            newState = {
                ...initState,
                [action.name]: action.value
            };
            return newState; 
        case "hue":
            newState = {
                ...initState,
                [action.name]: action.value
            };
            return newState; 
        case "blur":
            newState = {
                ...initState,
                [action.name]: action.value
            };
            return newState;
        case "gray":
            newState = {
                ...initState,
                [action.name]: action.value
            };
            return newState; 
        default:
            console.log("Switch Case Error", initState, action);
    };
};

//>>>>> COMPONENT - ChartGif
function ChartGifNew({ gifMeta, onDisplay }) {

    //>>>>> 9 - Settings Reducer <<<<<//
    const [settings, dispatchSettings] = useReducer(settingsReducer, initSettings);

    const [styles, setStyles] = useState({
        iframeWrap: {
            display: "inline-flex",
            justifyContent: "flex-start",
            alignItems: "center",
            //flex: "1 1 " + scaledheight,
            //width: settings.width + "px",
            padding: "0",
            margin: "0",
            border: "none",
        },
        iframeAct: {
            backgroundColor: "#f7f8ff",
            //height: scaledheight + "px",
            //width: settings.width + "px",
            borderRadius: "0",
            padding: "0",
            margin: "0",
            border: "none",
        },
        menuWrap: {
            //width: settings.width + "px",
            //flex: "1 1 auto",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0",
            margin: "0"
        }
    });
    useEffect(() => {

        const updateStyles = () => {
            if (!gifMeta || !settings) {
                return;
            } else {
                const scaledHeight = Math.round(settings.width /
                    (gifMeta.yWidth / gifMeta.yHeight));
                const stylesOut = {
                    iframeWrap: {
                        display: "inline-flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flex: "1 1 " + scaledHeight,
                        width: settings.width + "px",
                        padding: "0",
                        margin: "0",
                        border: "none",
                        opacity: settings.clear,
                        transform: "rotateX(" + settings.spinX + "deg) rotateY(" + settings.spinY + "deg) rotateZ(" +
                            settings.angle + "deg) skewX(" + settings.skewX + "deg) skewY(" + settings.skewY + "deg)",
                    },
                    iframeAct: {
                        backgroundColor: "#f7f8ff",
                        height: scaledHeight + "px",
                        width: settings.width + "px",
                        borderRadius: settings.radius + "%",
                        padding: "0",
                        margin: "0",
                        border: "none",
                        filter: "contrast(" + settings.contrast + ") hue-rotate(" + settings.hue + "deg) blur(" +
                            settings.blur + "px) grayscale(" + settings.gray + ")"
                    },
                    menuWrap: {
                        width: settings.width + "px",
                        flex: "1 1 auto",
                        display: "flex",
                        flexFlow: "row nowrap",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0",
                        margin: "0"
                    },
                    scaledHeight: scaledHeight
                }
                setStyles(stylesOut);
            };
        };
        updateStyles();
    }, [settings, gifMeta]);

    //>>>>> Debug <<<<<//
    //console.log(props);
    //console.log(settings);
    //console.log(styles);

    //>>>>> Return <<<<<//
    return (
        <Draggable
            allowAnyClick={false}
            axis="both"
            //bounds="parent"
            //defaultClassName={}
            //defaultClassNameDragging={}
            //defaultClassNameDragged={}
            //defaultPosition={{x: 0, y: 0}}
            //onMouseDown={(e) => { console.log(e) }}
            //onStart={(e) => { console.log(e) }}
            //onDrag={(e) => { console.log(e) }}
            //onStop={(e) => { console.log(e) }}
            //positionOffset={{ x: "number | string", y: "number | string" }}
            //scale={}
        >   
            <div>                   
                {gifMeta && styles ? (
                    <div
                        className="Base-block-flexCol"
                    >  
                        <div style={styles.iframeWrap} >
                            <div className="Cellophane-drag" >
                                <iframe
                                    title="Drag Me"
                                    src={gifMeta.url}
                                    style={styles.iframeAct}
                                    height={styles.scaledheight}
                                    width="auto"
                                />
                            </div>
                        </div>
                        <div style={styles.menuWrap} >
                            <div style={{ flex: "1 1 45%", textAlign: "center" }}>
                                <GifPopup
                                    onDispatch={(e) => dispatchSettings(e)}
                                    gifSettings={settings}
                                />
                            </div>
                            <div className="ChartGif-button-wrap" >
                                <button
                                    className="ChartGif-button"
                                    style={{ height: "2em", width: "2em" }}
                                    onClick={() => {
                                        onDisplay({
                                            type: "remove-gif",
                                            data: {
                                                index: gifMeta.index
                                            }
                                        })
                                    }}
                                >X</button>
                            </div>
                        </div>  
                    </div>
                ) : (null)}     
            </div>                         
        </Draggable>
    );
};

export default (ChartGifNew);

